import { useCallback } from 'react';

const useNumberFormatter = () => {
  const formatNumber = useCallback((number) => {
    return new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  }, []);

  return formatNumber;
};

export default useNumberFormatter;

import React, { useState, useEffect } from 'react';
import { Box, Grid, Tabs, Typography, IconButton, Menu, MenuItem, Collapse } from '@mui/material';
import { AntTab } from '../../components/CustomStyles';
import CustomSelect from '../../components/CustomSelect';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import TabCreditRisk from './TabCreditRisk';
import { BLUE, BLACK, CONTENT_BOX, SUBTITLE_TEXT, TITLE_TEXT } from '../../theme';
import {Menu as MenuIcon, Settings as SettingsIcon} from '@mui/icons-material';
import { useResponsive } from '../../hooks/useResponsive';


const tabMenuOptions = [
    'Riesgo de Crédito y Contraparte',
]

const dates = [
    '01/2023',
    '02/2023',
    '03/2023',
    '04/2023'
];

const entities = [
    'Grupo Consolidado',
    'Banco de Crédito Cooperativo',
    'Cajamar Caja Rural',
    'Caixa Rural Torrent',
    'Caixa Rural Vila-real',
    'Caixaltea',
    'Caixa Rural Burriana',
    'Caixa Rural Nules',
    'Caixacallosa',
    'Caixapetrer',
    'Caixalqueries',
    'Caixa Sant Vicent',
    'Caja Rural de Cheste',
    'Caixa Rural d\'Alginet',
    'Caja Rural de Villar',
    'Caixaturís',
    'Caixa Rural Vilavella',
    'Caixa Rural Almenara',
    'Caixa Rural Vilafamés',
    'Caixa Rural Xilxes',
];

const MainSettingsMenu = (props) => {
    const { dates, execution, handleOnChangeExecution, entities, setSelectedValue, setInputValue, isLabelVisible } = props
    return (
        <>
            <Grid
                item
                sm={6}
                md={4}
                lg={3}
                xl={2}
                sx={{ pr: '24px' }}
            >
                <CustomSelect
                    title={'Ejecución'}
                    selectItems={dates}
                    value={execution}
                    onChange={handleOnChangeExecution}
                    horizontal
                />
            </Grid>
            <Grid
                item
                sm={6}
                md={4}
                lg={3}
                xl={2}
                sx={{ pl: '26px' }}
            >
                <CustomAutocomplete
                    title={'Entidad'}
                    autocompleteItems={entities}
                    setSelectedValue={setSelectedValue}
                    setInputValue={setInputValue}
                    isLabelVisible={isLabelVisible}
                />
            </Grid>
        </>
    );
}

export default function CDMExecutions() {
    const { isBelowMdScreen } = useResponsive();
    const [execution, setExecution] = useState('');
    const [tab, setTab] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [expandedMainSettings, setExpandedMainSettings] = useState(false);
    

    // Determine if the label should be visible
    const isLabelVisible = !inputValue && !selectedValue;


    useEffect(() => {
        // Check if the screen is below xl and expandedSettings is true
        if (!isBelowMdScreen && expandedMainSettings) {
            // Set expandedSettings to false
            setExpandedMainSettings(false);
        }
    }, [isBelowMdScreen]);

    const handleOnChangeExecution = (event) => {
        setExecution(event.target.value);
    };

    const handleChangeTab = (_event, value) => {
        // setTab(value);
        setTab(0);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setTab(index);
        handleMenuClose();
    };

    const handleExpandMainSettings = () => {
        setExpandedMainSettings(!expandedMainSettings);
    };

    return (
        <>
            <Box
                sx={CONTENT_BOX}
            >
                <Grid container>
                    <Grid
                        item
                        xs={11}
                        md={4}
                        lg={6}
                        xl={8}
                    >
                        <Typography
                            color={BLUE}
                            sx={TITLE_TEXT}
                        >
                            CdM Ejecuciones
                        </Typography>
                    </Grid>
                    {isBelowMdScreen ? (
                        <Grid
                            item
                            xs={1}
                            sx={{ pl: '26px' }}
                        >
                            <IconButton sx={{color: BLUE}} onClick={handleExpandMainSettings}>
                                <SettingsIcon />
                            </IconButton>
                        </Grid>
                    )
                    :
                    (
                        <MainSettingsMenu
                            dates={dates}
                            execution={execution}
                            handleOnChangeExecution={handleOnChangeExecution}
                            entities={entities}
                            setSelectedValue={setSelectedValue}
                            setInputValue={setInputValue}
                            isLabelVisible={isLabelVisible}
                        />
                    )}
                </Grid>
                <Collapse in={expandedMainSettings} timeout="auto" unmountOnExit>
                    <Grid container>
                        {isBelowMdScreen && (
                            <MainSettingsMenu
                                dates={dates}
                                execution={execution}
                                handleOnChangeExecution={handleOnChangeExecution}
                                entities={entities}
                                setSelectedValue={setSelectedValue}
                                setInputValue={setInputValue}
                                isLabelVisible={isLabelVisible}
                            />
                        )}
                    </Grid>
                </Collapse>
            </Box >

            {!isBelowMdScreen && (
                <Tabs 
                    sx={{ml: '66px', mb: '0px', minHeight: "38px", height: "38px"}}
                    value={tab}
                    onChange={handleChangeTab}
                >
                    <AntTab
                        tab={0}
                        label={tabMenuOptions[0]}
                    />
                </Tabs>
            )}
            <Box sx={{...CONTENT_BOX, mt: '0px'}}>
                {isBelowMdScreen && (
                    <Box display={'flex'}>
                        <Typography
                            sx={{...TITLE_TEXT, mt: '9px'}}
                            color={BLUE}
                        >
                            {tabMenuOptions[tab]}
                        </Typography>
                        <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem 
                                onClick={() => handleMenuItemClick(0)}
                                sx={{ color: tab === 0 ? BLUE : BLACK }}
                            >
                                {tabMenuOptions[0]}
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
                {tab === 0 && (
                    <Box>
                        <TabCreditRisk />
                        
                    </Box>
                )} 
            </Box>
        </>
    );
}
import { useContext } from 'react'
import { ProcessesConfigContext } from '../context/ProcessesConfigContext'
import { configCreditRiskService } from '../services/configCreditRiskService'
import { configOperationalRiskService } from '../services/configOperationalRisk';

export function useProcessesConfig () {
    const { configSubSections, setConfigSubSections, currentConfig, setCurrentConfig } = useContext(ProcessesConfigContext)
    const { getConfigCreditRisk, putConfigCreditRiskAdditionalParam, putConfigCreditRiskRelationEpigraphsAmounts } = configCreditRiskService();
    const { getConfigOperationalRisk, putConfigOperationalRisk } = configOperationalRiskService();


    const getSubSectionCurrentConfig = (subSectionName) => {
        return currentConfig[subSectionName]
    }

    const fetchCreditRiskProcessesConfig = async () => {
        const data = await getConfigCreditRisk();
        if (data) {
            setConfigSubSections(data.sub_sections);
            setCurrentConfig(data.current_values)
        }
    };

    const fetchOperationalRiskProcessesConfig = async () => {
        const data = await getConfigOperationalRisk();
        if (data) {
            setConfigSubSections(data.sub_sections);
            setCurrentConfig(data.current_values)
        }
    };

    const updateCreditRiskProcessesConfig = async (subSectionName, newConfigValues) => {
        const newConfig = {...currentConfig, [subSectionName]: newConfigValues};
        const auxNewConfig = {...newConfig};
        delete auxNewConfig["Relación epígrafes importe"];
        const data = await putConfigCreditRiskAdditionalParam(auxNewConfig);
        if (data) {
            setCurrentConfig(newConfig);
        }
        return data;
    };

    const updateCreditRiskEpigraphsConfig = async (subSectionName, newConfigValues) => {
        const data = await putConfigCreditRiskRelationEpigraphsAmounts({[subSectionName]: newConfigValues});
        if (data) {
            const newConfig = {...currentConfig, [subSectionName]: newConfigValues};
            setCurrentConfig(newConfig);
        }
        return data;
    };

    const updateOperationalRiskConfig = async (subSectionName, newConfigValues) => {
        const data = await putConfigOperationalRisk({[subSectionName]: newConfigValues});
        if (data) {
            const newConfig = {...currentConfig, [subSectionName]: newConfigValues};
            setCurrentConfig(newConfig);
        }
        return data;
    };

     return { 
        configSubSections, 
        currentConfig, 
        fetchCreditRiskProcessesConfig, 
        fetchOperationalRiskProcessesConfig,
        updateCreditRiskProcessesConfig, 
        updateCreditRiskEpigraphsConfig, 
        updateOperationalRiskConfig,
        getSubSectionCurrentConfig 
    }
}
import React, { useState, useMemo, useEffect } from 'react';
import { Collapse, Paper, Table, TableBody, TableContainer, Tooltip, Box,
         TableHead, Typography, Button, Dialog, DialogActions, 
         DialogContent, DialogTitle, IconButton, Select, MenuItem, FormControl } from '@mui/material';
import { StyledTableCell, StyledTableRow } from '../../components/CustomStyles';
import ExpandIcon from '../../components/ExpandIcon';
import { Cancel, CheckCircle, UnfoldMore, UnfoldLess, SubdirectoryArrowRight, HelpOutline, CleaningServices } from '@mui/icons-material/';
import { BLUE, TITLE_TEXT } from '../../theme';
import { useProcessesConfig } from '../../hooks/useProcessesConfig';


const notShowingColumns = ["id_activo_valor_razonable", "id_tipo_cartera_ifrs9"]

const Row = ({ row, dialogParameters, subEntryMargin, expandAll, handleChangeField }) => {
    const [expand, setExpand] = useState(expandAll);
    

    useEffect(() => {
        setExpand(expandAll)
    }, [expandAll])

    const handleExpand = () => setExpand(!expand);

    const renderField = (key, value) => {
        const param = dialogParameters[key];
        if (param.type === 'enum') {
            if (key === 'id_epigrafe_phc') {
                return (
                    <StyledTableCell 
                        key={key} 
                        style={{ width: '60%', paddingLeft: '10px', paddingRight: '40px'}}
                    >
                        {value} - {param.values.find(item => item.value === value).desc}
                    </StyledTableCell>
                );
            }
            else {
                var cellWidth = '40%'
                return (
                    <StyledTableCell 
                        key={key} 
                        style={{ width: cellWidth, paddingLeft: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        > 
                        <FormControl style={{width: '65%'}}>
                            <Select
                                value={value}
                                onChange={(e) => {
                                    handleChangeField(row.id_epigrafe_phc, key, e.target.value);
                                }}
                                displayEmpty
                                size="small"
                                sx={{ fontSize: '12px', fontWeight: 400, height: '23px', backgroundColor: 'white' }}
                                >
                                <MenuItem key={"Empty"} value={""} sx={{ fontSize: '12px', fontWeight: 400 }}>
                                    {"Seleccione una opción"}
                                </MenuItem>
                                {param.values.map((option) => (
                                    <MenuItem key={option.value} value={option.value} sx={{ fontSize: '12px', fontWeight: 400 }}>
                                        {option.value} - {option.desc}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </StyledTableCell>
                );
            }
        }
    };

    return (
        <>
            {/* Main table entry */}
            <StyledTableRow sx={{ height: 30 }}>
                {subEntryMargin >= 15 && 
                    <StyledTableCell style={{paddingLeft: subEntryMargin > 15 ? '5px' : '0px'}} sx={{ width: '1%', background: 'white'}} >
                        <SubdirectoryArrowRight fontSize="small" />
                    </StyledTableCell>
                }
                {
                    renderField('id_epigrafe_phc', row["id_epigrafe_phc"])                    
                }
                {
                    renderField('id_componente', row["id_componente"])                    
                }
                <StyledTableCell sx={{ width: '2%'}} >
                    {row.sub_items.length > 0 &&
                        <ExpandIcon
                            size={'small'}
                            open={expand}
                            onClick={handleExpand}
                        />
                    }
                </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell colSpan={Object.keys(dialogParameters).length + 2} sx={{ backgroundColor: 'white' }} >
                    <Collapse
                        in={expand}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 4px', paddingLeft: subEntryMargin + 'px', marginBottom: "-4px"}}>
                            <TableBody >
                                {row.sub_items.map((subEntry, index) => (
                                    <Row 
                                        key={index} 
                                        row={subEntry} 
                                        subEntryMargin={subEntryMargin+15} 
                                        dialogParameters={dialogParameters} 
                                        expandAll={expandAll} 
                                        handleChangeField={handleChangeField} 
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
        </>
    );
}

export default function RelationEpigraphsComponentDialog({ subSectionName, open, toggleConfig, dialogDescription, dialogParameters }) {
    const { getSubSectionCurrentConfig, updateOperationalRiskConfig } = useProcessesConfig();
    const [currentConfig, setCurrentConfig] = useState(getSubSectionCurrentConfig(subSectionName));
    const [expandAll, setExpandAll] = useState(false);

    const filteredDialogParameters = useMemo(() => {
        const updatedDialogParameters = { ...dialogParameters };
        notShowingColumns.forEach(column => {
          delete updatedDialogParameters[column];
        });
        const sortedKeys = Object.keys(updatedDialogParameters).sort();
        const sortedDialogParameters = {};
            sortedKeys.forEach(key => {
                sortedDialogParameters[key] = updatedDialogParameters[key];
        });
        return sortedDialogParameters;
    }, [dialogParameters]);


    useEffect(() => {
        !open && setExpandAll(false);
    }, [open]);

    const handleExpandAll = () => setExpandAll(!expandAll);

    const onClickConfirm = () => {
        toggleConfig();
        if (!updateOperationalRiskConfig(subSectionName, currentConfig)) {
            setCurrentConfig(getSubSectionCurrentConfig(subSectionName));
        }
    }

    const onClickCancel = () => {
        toggleConfig();
        setCurrentConfig(getSubSectionCurrentConfig(subSectionName));
    }

    const handleChangeField = (id_epigrafe_phc, field, newValue) => {
        const updateConfig = (config, id, field, value) => {
            return config.map(item => {
                if (item.id_epigrafe_phc === id) {
                    return {
                        ...item,
                        [field]: value,
                        sub_items: updateConfig(item.sub_items, id, field, value)
                    };
                } else {
                    return {
                        ...item,
                        sub_items: updateConfig(item.sub_items, id, field, value)
                    };
                }
            });
        };

        setCurrentConfig(prevConfig => updateConfig(prevConfig, id_epigrafe_phc, field, newValue));
    };

    return (
        <Dialog
            open={open}
            onClose={toggleConfig}
            maxWidth='xl'
            fullWidth
        >
            <DialogTitle
                color={BLUE}
                sx={TITLE_TEXT}
            >
                {subSectionName}
            </DialogTitle>
            <DialogContent >
                <Typography>{dialogDescription}</Typography>
                <TableContainer
                    component={Paper}
                    sx={{ boxShadow: 'none', mt: '7px', overflowX: 'auto', maxHeight: 400, minWidth: '900px' }}
                >
                    <Table stickyHeader sx={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}>
                        <TableHead>
                            <StyledTableRow sx={{ height: 30 }}>
                                {Object.entries(filteredDialogParameters).map(([key, param]) =>
                                    <StyledTableCell style={{paddingLeft: '10px'}} key={key} >{param.display_name} </StyledTableCell>
                                )}
                                <StyledTableCell>
                                    <IconButton sx={{mb: "2px"}} size="small" onClick={handleExpandAll}>
                                        {expandAll ? <UnfoldLess fontSize="small" /> : <UnfoldMore fontSize="small" />}
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {currentConfig.map((row) => (
                                <Row 
                                    key={row.id_epigrafe_phc} 
                                    row={row} 
                                    dialogParameters={filteredDialogParameters} 
                                    subEntryMargin={0} 
                                    expandAll={expandAll}
                                    handleChangeField={handleChangeField}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions
                sx={{ mb: '20px', justifyContent: 'center' }}
            >
                <Button
                    variant='contained'
                    sx={{ fontSize: '13px', textTransform: 'none' }}
                    endIcon={<Cancel />}
                    onClick={onClickCancel}
                >
                    Cancelar
                </Button>
                <Button
                    variant='contained'
                    sx={{ ml: '20px', fontSize: '13px', textTransform: 'none' }}
                    endIcon={<CheckCircle />}
                    onClick={onClickConfirm}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog >
    );
}

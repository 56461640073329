import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const LoadingSpinner = (height, width) => (
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={height ? height : "50vh"}
        width={width ? width : null}
    >
        <CircularProgress />
    </Box>
);

export default LoadingSpinner;

import { React, useState, useEffect } from 'react';
import { Stack, Table, TableBody, IconButton, TextField, TableHead, TableRow, Typography } from '@mui/material';
import { Edit, CheckCircle as CheckIcon, Cancel as CancelIcon, Delete as DeleteIcon, AddCircleOutline } from '@mui/icons-material';
import { StyledTableCell, StyledTableRow } from '../../components/CustomStyles';
import ConfirmDialog from '../../components/ConfirmDialog';
import { useAuth } from '../../hooks/useAuth';
import { useProcessComments } from '../../hooks/useProcessComments';
import { BLUE } from '../../theme';



const CommentRow = (props) => {
    const { processId, comment, toggleCommentInput, isAddingComment } = props
    const { refreshComments, updateComment, eliminateComment } = useProcessComments()
    const { account } = useAuth();
    const [isEditingComment, setIsEditingComment] = useState(false)
    const [currentComment, setCurrentComment] = useState(comment)
    const [openDialog, setOpenDialog] = useState(false)

    useEffect(() => {
        (isAddingComment && isEditingComment) && toggleEditComment()
        setCurrentComment(comment)
    }, [isAddingComment])

    useEffect(() => {
        (isAddingComment && isEditingComment) && toggleCommentInput()
    }, [isEditingComment])

    const toggleEditComment = () => {
        setIsEditingComment(!isEditingComment)
    }

    const handleEditComment = (event) => {
        setCurrentComment({...currentComment, comment_desc: event.target.value})
    }

    const handleUpdateComment = async() => {
        await updateComment(currentComment)
        toggleEditComment()
        refreshComments(processId)
    }

    const handleEliminateComment = async(comment_id) => {
        await eliminateComment(comment_id)
        setOpenDialog(false)
        refreshComments(processId)
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    return (
        <>
            <TableRow sx={{ height: 30 }}>
                <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }} colSpan={2}>
                    {isEditingComment ? (
                        <TextField
                            size="small"
                            variant="standard"
                            sx={{ width: '90%' }}
                            value={currentComment.comment_desc}
                            onChange={(event) => handleEditComment(event)}
                            fullWidth
                            InputProps={{
                            style: {
                                fontSize: 13, 
                                height: '100%' 
                            }
                            }}
                        />
                    ) : (
                        currentComment.comment_desc
                    )}  
                </StyledTableCell>
                <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }}>{comment.update_date}</StyledTableCell>
                <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }}>{comment.user}</StyledTableCell>
                <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }}>
                    {account.username === comment.mail && 
                        <Stack
                            direction="row"
                            justifyContent={'center'}
                        >
                            <IconButton size={'small'} onClick={isEditingComment ? handleUpdateComment : toggleEditComment}>
                                {isEditingComment ? <CheckIcon fontSize='small' /> : <Edit fontSize='small' />}
                            </IconButton>
                            <IconButton size={'small'} onClick={isEditingComment ? toggleEditComment : handleClickOpenDialog} >
                                {isEditingComment ? <CancelIcon fontSize='small' /> : <DeleteIcon fontSize='small' />}
                            </IconButton>
                        </Stack>
                    }
                </StyledTableCell>
            </TableRow>

            {/* Diálogo de confirmación */}
            <ConfirmDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={() => handleEliminateComment(comment.comment_id)}
                title="Confirmar"
                description="¿Estás seguro de que deseas eliminar este comentario?"
            />
        </>
    );
}


export const CommentsTable = (props) => {
    const { row } = props;
    const { refreshComments, storeComment } = useProcessComments()
    const [newComment, setNewComment] = useState('');
    const [isAddingComment, setIsAddingComment] = useState(false);
    
    const handleWriteComment = (event) => {
        setNewComment(event.target.value)
    };

    const toggleCommentInput = () => {
        setIsAddingComment(!isAddingComment)
        setNewComment('')
    };

    const handleStoreComment = async () => {
        await storeComment(row.process_id, newComment)
        await refreshComments(row.process_id)
        toggleCommentInput()
    };

    return (
        <>
            <Table sx={{marginBottom: '8px'}}>
                <TableHead>
                    <StyledTableRow sx={{ height: 30 }}>
                        {row.comments.length > 0 ?
                        <>
                            <StyledTableCell style={{ fontWeight: 500 }} colSpan={2} width={"15%"}>Comentario</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: 500 }} width={"10%"}>Fecha</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: 500 }} width={"8%"}>Usuario</StyledTableCell>
                        </>
                        :
                        <>
                            <StyledTableCell style={{ fontWeight: 500, borderBottom: "none" }} colSpan={2} width={"15%"}>
                                <Stack
                                    direction="row"
                                    alignItems={'center'}
                                >
                                    <Typography sx={{ fontSize: '13px', fontWeight: 400 }}>No hay ningún comentario.</Typography>
                                    <IconButton onClick={ toggleCommentInput } >
                                        <AddCircleOutline style={{fontSize: '1.1rem'}} sx={{ml: '2px', mb: '3px'}}/>
                                    </IconButton>
                                    
                                </Stack>
                                
                            </StyledTableCell>
                            <StyledTableCell style={{ borderBottom: "none" }} width={"10%"}></StyledTableCell>
                            <StyledTableCell style={{ borderBottom: "none" }} width={"8%"}></StyledTableCell>
                        </>
                        }
                        
                        
                        <StyledTableCell style={{ fontWeight: 500, borderBottom: row.comments.length > 0 ? 'solid 1px' + BLUE : "none" }} width={"2%"}>
                            {(!isAddingComment && row.comments.length > 0) &&
                                    <Stack
                                        direction="row"
                                        justifyContent={'center'}
                                    >
                                        <IconButton onClick={ toggleCommentInput } >
                                            <AddCircleOutline style={{fontSize: '1.1rem'}} sx={{ml: '2px'}}/>
                                        </IconButton>
                                    </Stack>
                            }
                        </StyledTableCell>                        
                    </StyledTableRow>
                </TableHead>
                
                <TableBody>
                    {row.comments.map((comment) => (
                        <CommentRow 
                            key={comment.comment_id}
                            comment={comment}
                            processId={row.process_id}
                            toggleCommentInput={toggleCommentInput}
                            isAddingComment={isAddingComment}
                        />
                    ))}
                </TableBody>
                <TableBody>
                    <TableRow>
                        {isAddingComment &&
                            <>
                                <StyledTableCell colSpan={1}>
                                    <TextField
                                        variant="outlined"
                                        value={newComment}
                                        onChange={handleWriteComment}
                                        fullWidth 
                                        sx={{ marginTop: '5px', borderRadius: '30px', '& .MuiInputBase-input': {fontSize: '13px'}, '& .MuiInputBase-root': { height: '32px' } }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Stack direction="row" justifyContent={'left'} alignItems={'center'}>
                                        <IconButton size={'small'} onClick={ () => {handleStoreComment();} }>
                                            <CheckIcon fontSize='small' />
                                        </IconButton>
                                        <IconButton size={'small'} onClick={ toggleCommentInput }>
                                            <CancelIcon fontSize='small' />
                                        </IconButton>
                                    </Stack>
                                </StyledTableCell>
                            </>
                        }
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}
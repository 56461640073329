// useResponsive.js
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


export const useResponsive = () => {
  const theme = useTheme();
  const isBelowSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelowMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowLgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isBelowXlScreen = useMediaQuery(theme.breakpoints.down('xl'));

  return { isBelowSmScreen, isBelowMdScreen, isBelowLgScreen, isBelowXlScreen };
};

import { useState, useEffect } from 'react'
import { parametrizationService } from '../services/parametrizationService';

export function useProcessNames () {
    const {getParametrization} = parametrizationService();
    const [processNamesList, setProcessNamesList] = useState([]);

    useEffect(() => {
        fetchProcessNames();
    }, []);

    const fetchProcessNames = async () => {
        const data = await getParametrization({parameterCode: 'processes'});
        if (data) {
            const namesList = data.processes.map(value => value.name);
            setProcessNamesList(namesList);
        }
    }

  return { processNamesList }
}
import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function executionProcessService() {
    const { apiCall, addQueryParameters } = useApi();
    const { startLoading, stopLoading, setDialogErrorState, clearDialogError } = useErrors();

    const postExecutionProcessCreditCounterpartyRisk = async ({ params, payload }) => {
        const key = 'postExecutionProcessCreditCounterpartyRisk';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/execution/process/credit-counterparty-risk");
        fileUrl = addQueryParameters(fileUrl, params);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('POST', fileUrl, payload);
            if (!response.success) {
                setDialogErrorState(key, "Error ejecutando el proceso Riesgo de Crédito y Contraparte.");
                Sentry.captureException(response, {
                    tags: { service: 'executionProcessService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error ejecutando el proceso Riesgo de Crédito y Contraparte.");
            Sentry.captureException(error, {
                tags: { service: 'executionProcessService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }


    const postExecutionProcessMarketRisk = async ({ params, payload }) => {
        const key = 'postExecutionProcessMarketRisk';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/execution/process/market-risk");
        fileUrl = addQueryParameters(fileUrl, params);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('POST', fileUrl, payload);
            if (!response.success) {
                setDialogErrorState(key, "Error ejecutando el proceso Riesgo de Mercado.");
                Sentry.captureException(response, {
                    tags: { service: 'executionProcessService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error ejecutando el proceso Riesgo de Mercado.");
            Sentry.captureException(error, {
                tags: { service: 'executionProcessService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }


    const postExecutionProcessOperationalRisk = async ({ params, payload }) => {
        const key = 'postExecutionProcessOperationalRisk';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/execution/process/operational-risk");
        fileUrl = addQueryParameters(fileUrl, params);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('POST', fileUrl, payload);
            if (!response.success) {
                setDialogErrorState(key, "Error ejecutando el proceso Riesgo Operacional.");
                Sentry.captureException(response, {
                    tags: { service: 'executionProcessService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error ejecutando el proceso Riesgo Operacional.");
            Sentry.captureException(error, {
                tags: { service: 'executionProcessService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    return { postExecutionProcessCreditCounterpartyRisk, postExecutionProcessMarketRisk, postExecutionProcessOperationalRisk }
}
import { React, useState } from 'react';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { BLUE, CONTENT_BOX, TITLE_TEXT } from '../../theme';
import StepOne from './steps/StepOne';
import FinalStep from './steps/FinalStep';
import { useResponsive } from '../../hooks/useResponsive';
import dayjs from 'dayjs';


const steps = [
    'Configuración de la ejecución',
    'Resumen de la ejecución'
];

export default function NewExecution() {
    const { isBelowSmScreen } = useResponsive();
    const [activeStep, setActiveStep] = useState(0);
    const [selectFilters, setSelectFilters] = useState({process: '', ebaNormative: '', fullDataDate: null});
    const [checks, setChecks] = useState({lastLoad: true});
    const [selectedEntryFiles, setSelectedEntryFiles] = useState({});

    const handleOnClickNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleOnClickBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <Box
                sx={CONTENT_BOX}
            >
                <Typography
                    color={BLUE}
                    sx={TITLE_TEXT}
                >
                    Nueva ejecución
                </Typography>
            </Box>

            <Box sx={CONTENT_BOX}>
                <Stepper
                    activeStep={activeStep}
                    sx={{ mt: '8px' }}
                    orientation={isBelowSmScreen ? "vertical" : "horizontal"}
                >
                    {steps.map((label) =>
                        <Step
                            key={label}
                            sx={{ pl: 0 }}
                        >
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    )}
                </Stepper>
            </Box>
            <StepOne
                activeStep={activeStep}
                handleOnClickNext={handleOnClickNext}
                selectFilters={selectFilters}
                setSelectFilters={setSelectFilters}
                checks={checks}
                setChecks={setChecks}
                selectedEntryFiles={selectedEntryFiles}
                setSelectedEntryFiles={setSelectedEntryFiles}
            />
            <FinalStep
                activeStep={activeStep}
                numSteps={steps.length}
                onClickBack={handleOnClickBack}
                selectFilters={selectFilters}
                checks={checks}
                selectedEntryFiles={selectedEntryFiles}
            />

        </>
    );
}
import React, { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, IconButton, Paper, Stack, Table, TableBody, TableContainer, 
        TableHead, TableRow, Pagination, FormControl, Select, MenuItem, Typography, TextField } from '@mui/material';
import { Download, Edit, CheckCircle as CheckIcon, Cancel as CancelIcon, Sync } from '@mui/icons-material';
import { StyledTableCell, StyledTableRow } from '../../../components/CustomStyles';
import { GREY } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import { loadFilesService } from '../../../services/loadFilesService';
import LoadingSpinner from '../../../components/LoadingSpinner';
import InLineError from '../../../components/InLineError';
import { useErrors } from '../../../hooks/useErrors';
import { processStateService } from '../../../services/processStateService';
import { parametrizationService } from '../../../services/parametrizationService';
import { entryFilesStateService } from '../../../services/entryFilesStateService';

const changeMonthStructure = (month) => {
  var changedMonth = String(month + 1);
  if (changedMonth.length === 1) {
      changedMonth = '0' + changedMonth;
  }
  return changedMonth;
}

const icons = (row, isEditing, alternateEdit, saveEdit, cancelEdit, statesList, refreshEntryFileState) => {
  const { getLoadFile } = loadFilesService();

  const downloadInputFile = () => {
    const fileDownloadName = row.data_type + '_' + row.file_id + '.zip';
    const [year, month] = row.data_date.split("-");
    getLoadFile({ 
        data_type: row.data_type, 
        file_id: row.file_id, 
        month: month, 
        year: year, 
        fileDownloadName: fileDownloadName 
    });
  };

  const isFinalState = () => {
    const finalState = statesList.filter(state => state.final_state);
    return finalState ? finalState.is_final : false;
  }

  return (
    <Stack
      direction="row"
      justifyContent={'flex-end'}
    >
      <IconButton size={'small'} onClick={isEditing ? saveEdit : alternateEdit}>
        {isEditing ? <CheckIcon fontSize='small' /> : <Edit fontSize='small' />}
      </IconButton>
      <IconButton size={'small'} onClick={isEditing ? cancelEdit : downloadInputFile } >
        {isEditing ? <CancelIcon fontSize='small' /> : <Download fontSize='small' />}
      </IconButton>
      { !(isFinalState()) &&(
            <IconButton
                size={'small'}
                onClick={() => refreshEntryFileState({file_id: row.file_id, load_date: row.load_date, data_type: row.data_type})}
            >
                {<Sync fontSize='small' />}
            </IconButton>
        )}
    </Stack>
  )
};


const Row = ({ row, index, selectedEntryFile, handleEdit, handleOnClickRow, statesList, refreshEntryFileState }) => {
  const { putLoadFileDataSet } = loadFilesService();
  const prevDataSet = useRef(row.data_set);
  const [isEditing, setIsEditing] = useState(false);

  const isSelected = () => row.file_id === selectedEntryFile?.fileID;

  const alternateEdit = () => setIsEditing(!isEditing);

  const saveEdit = async() => {
    await putLoadFileDataSet({ file_id: row.file_id, data_set: row.data_set });
    prevDataSet.current = row.data_set;
    alternateEdit();
  };

  const cancelEdit = async() => {
    handleEdit(index, prevDataSet.current);
    alternateEdit();
  };

  const handleChange = (event) => {
    handleEdit(index, event.target.value);
  };

  return (
    <TableRow
      selected={isSelected()}
      sx={{ fontWeight: 700, backgroundColor: GREY, }}
    >
      <StyledTableCell sx={{ pr: '11px' }}>
        <Checkbox
          checked={isSelected()}
          sx={{ height: '25px', width: '25px', backgroundColor: GREY }}
          onClick={() => handleOnClickRow(row)}
        />
      </StyledTableCell>
      <StyledTableCell>{row.data_date}</StyledTableCell>
      <StyledTableCell>
        {isEditing ? (
          <TextField
            size="small"
            variant="standard"
            sx={{ width: '90%' }}
            value={row.data_set}
            onChange={handleChange}
            fullWidth
            InputProps={{
              style: {
                fontSize: 13, 
                height: '100%' 
              }
            }}
          />
        ) : (
          row.data_set
        )}  
      </StyledTableCell>
      <StyledTableCell>{row.load_date}</StyledTableCell>
      <StyledTableCell>{row.user}</StyledTableCell>
      <StyledTableCell>{row.state}</StyledTableCell>
      <StyledTableCell >{icons(row, isEditing, alternateEdit, saveEdit, cancelEdit, statesList, refreshEntryFileState)}</StyledTableCell>
    </TableRow>
  );
}


export default function EntryFilesTable(props) {
  const { selectedEntryFile, handleOnClickRow, entryFiles, setEntryFiles, paginationData, setPaginationData, dataType, selectFilters } = props;
  const { isBelowLgScreen } = useResponsive();
  const { getLoadFiles } = loadFilesService();
  const { getEntryFileState } = entryFilesStateService();
  const {getParametrization } = parametrizationService();
  const { loading, inLineErrors } = useErrors();
  const [statesList, setStatesList] = useState([]);
  const paginationLimits = [5, 10, 20];

  useEffect(() => {
    fetchProcessStates()
  }, []);


  const fetchProcessStates = async () => {
    const states = await getParametrization({parameterCode: "states"});
    if (states) {
      setStatesList(states.states);
    }
  }

  const handleOnChangeSelectedPaginationLimit = async (event) => {
    const newPaginationLimit = event.target.value;
    if (newPaginationLimit <= paginationData.count) {
        const data = await getLoadFiles({
          data_type: dataType, 
          month: changeMonthStructure(selectFilters.fullDataDate.$M), 
          year: selectFilters.fullDataDate.$y, 
          limit: newPaginationLimit, 
          start: 0
        })
        if (data) {
            setEntryFiles(data.results);
            setPaginationData({start: data.start, limit: data.limit, count: data.count, page: 1})
        }
      }
  };

  const handleChangePage = async (event, value) => {
      const newPage = value;
      if (newPage != paginationData.page) {
          const data = await getLoadFiles({
            data_type: dataType, 
            month: changeMonthStructure(selectFilters.fullDataDate.$M), 
            year: selectFilters.fullDataDate.$y, 
            limit: paginationData.limit, 
            start: (newPage - 1) * paginationData.limit
          })
          if (data) {
              setEntryFiles(data.results);
              setPaginationData({start: data.start, limit: data.limit, count: data.count, page: newPage})
          }
      }
  };

  const refreshEntryFileState = async ({file_id, load_date, data_type}) => {
    const [year, month] = load_date.split("-");
    const data = await getEntryFileState({year: year, month: month, file_id: file_id, data_type: data_type});
    if (data) {
      const newData = entryFiles.map((entryFile) => {
          if (file_id === entryFile.file_id) {
              return { ...entryFile, state: data.state };  
          }
          return process;
      });
      setEntryFiles(newData);  
  }
}


  const handleEdit = (index, newValue) => {
    const newData = entryFiles.map((item, i) => {
      if (i === index) {
        return { ...item, data_set: newValue };
      }
      return item;
    });
    setEntryFiles(newData);
  };

  if (loading.getLoadFiles) {
    return (
      <LoadingSpinner/>
    )
  }
  else if (inLineErrors.getLoadFiles) {
    return (
      <InLineError width={"100%"} length={"100%"} errorMessage={inLineErrors.getLoadFiles} />
    )
  }
  else {
    return (
      <Box
        component={Paper}
        sx={{ boxShadow: 'none' }}
      >
        <TableContainer 
          component={Paper}
          sx={{ overflowX: isBelowLgScreen ? 'auto' : 'hidden'}}
        >
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 8px', minWidth: '900px'}}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell width={"4%"} />
                <StyledTableCell width={"9%"}>Fecha de datos</StyledTableCell>
                <StyledTableCell width={"26%"}>Conjunto de datos</StyledTableCell>
                <StyledTableCell width={"11%"}>Fecha de importación</StyledTableCell>
                <StyledTableCell width={"10%"}>Usuario importación</StyledTableCell>
                <StyledTableCell width={"8%"}>Estado</StyledTableCell>
                <StyledTableCell width={"6%"} />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {entryFiles.length > 0 ? entryFiles.map((row, index) => (
                <Row
                  key={index}
                  index={index}
                  row={row}
                  selectedEntryFile={selectedEntryFile}
                  handleOnClickRow={handleOnClickRow}
                  handleEdit={handleEdit}
                  statesList={statesList}
                  refreshEntryFileState={refreshEntryFileState}
                />
              ))
              :
              <StyledTableRow style={{ height: "30px"}}>
                  <StyledTableCell colSpan={7}>
                      <InLineError width={"100%"} length={"100%"} errorMessage={"No se han encontrado resultados."}/>
                  </StyledTableCell>
              </StyledTableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
            direction="row"
            spacing={2}
            justifyContent={'space-between'}
            sx={{ mt: '15px', mb: '15px' }}
        >
            <Stack
                direction="row"
                spacing={2}
                justifyContent={'space-between'}
            >
                <FormControl >
                    <Select
                        value={paginationData.limit}
                        onChange={handleOnChangeSelectedPaginationLimit}
                        sx={{ height: '32px' }}
                    >
                        {
                            paginationLimits.map(elem => <MenuItem key={elem} value={elem}>{elem}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <Typography
                    sx={{ fontSize: '13px', fontWeight: 400, pt: '8px' }}
                >
                    {`Mostrando del ${paginationData.start + 1} 
                    al ${Math.ceil(paginationData.count / paginationData.limit) === paginationData.page ? paginationData.start + entryFiles.length : paginationData.start + entryFiles.length} 
                    de ${paginationData.count} ejecuciones`}
                </Typography>
            </Stack>
            <Pagination
                count={Math.ceil(paginationData.count / paginationData.limit)}
                shape="rounded"
                variant='outlined'
                showFirstButton
                showLastButton
                color='primary'
                page={paginationData.page}
                onChange={handleChangePage}
            />
        </Stack>
      </Box >
    )
  }
}
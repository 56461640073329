import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function reportsMarketRiskService() {
    const { apiCall, addQueryParameters, handleDownload } = useApi();
    const { startLoading, stopLoading, setErrorState, clearError } = useErrors();

    const getExcelFile = async ({ year, month, process_id }) => {
        const key = 'getExcelFile';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/reports/market-risk/download-excel");
        var queryParams = {year, month, process_id};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const fileDownloadName = `Resumen_Riesgo_de_Mercado_${year}_${month}.xlsx`;
            const response = await handleDownload(fileUrl, fileDownloadName);
            if (!response.success) {
                setErrorState(key, "Error descargando el fichero excel.");
                Sentry.captureException(response, {
                    tags: { service: 'reportsMarketRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error descargando el fichero excel.");
            Sentry.captureException(error, {
                tags: { service: 'reportsMarketRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    const getMartketRiskReportChangeType = async ({year, month, process_id}) => {
        const key = 'getMartketRiskReportChangeType';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/reports/market-risk/change-type");
        var queryParams = {year, month, process_id};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando el Tipo de Cambio de Riesgo de Mercado.");
                Sentry.captureException(response, {
                    tags: { service: 'reportsMarketRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando el Tipo de Cambio de Riesgo de Mercado.");
            Sentry.captureException(error, {
                tags: { service: 'reportsMarketRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const getMartketRiskReportRawMaterials = async ({year, month, process_id}) => {
        const key = 'getMartketRiskReportRawMaterials';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/reports/market-risk/raw-materials");
        var queryParams = {year, month, process_id};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando Materias Primas de Riesgo de Mercado.");
                Sentry.captureException(response, {
                    tags: { service: 'reportsMarketRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando Materias Primas de Riesgo de Mercado.");
            Sentry.captureException(error, {
                tags: { service: 'reportsMarketRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const getMartketRiskReportRVGeneral = async ({year, month, process_id}) => {
        const key = 'getMartketRiskReportRVGeneral';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/reports/market-risk/rv-general");
        var queryParams = {year, month, process_id};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando RV General de Riesgo de Mercado.");
                Sentry.captureException(response, {
                    tags: { service: 'reportsMarketRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando RV General de Riesgo de Mercado.");
            Sentry.captureException(error, {
                tags: { service: 'reportsMarketRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const getMartketRiskReportRVSpecific = async ({year, month, process_id}) => {
        const key = 'getMartketRiskReportRVSpecific';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/reports/market-risk/rv-specific");
        var queryParams = {year, month, process_id};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando RV Específico de Riesgo de Mercado.");
                Sentry.captureException(response, {
                    tags: { service: 'reportsMarketRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando RV Específico de Riesgo de Mercado.");
            Sentry.captureException(error, {
                tags: { service: 'reportsMarketRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const getMartketRiskReportRFGeneral = async ({year, month, process_id}) => {
        const key = 'getMartketRiskReportRFGeneral';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/reports/market-risk/rf-general");
        var queryParams = {year, month, process_id};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando el RF General de Riesgo de Mercado.");
                Sentry.captureException(response, {
                    tags: { service: 'reportsMarketRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando el RF General de Riesgo de Mercado.");
            Sentry.captureException(error, {
                tags: { service: 'reportsMarketRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const getMartketRiskReportRFSpecific = async ({year, month, process_id}) => {
        const key = 'getMartketRiskReportRFSpecific';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/reports/market-risk/rf-specific");
        var queryParams = {year, month, process_id};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setErrorState(key, "Error encontrando el RF Específico de Riesgo de Mercado.");
                Sentry.captureException(response, {
                    tags: { service: 'reportsMarketRiskService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setErrorState(key, "Error encontrando el RF Específico de Riesgo de Mercado.");
            Sentry.captureException(error, {
                tags: { service: 'reportsMarketRiskService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    return { getExcelFile, getMartketRiskReportChangeType, getMartketRiskReportRawMaterials, getMartketRiskReportRVGeneral, getMartketRiskReportRVSpecific, getMartketRiskReportRFGeneral, getMartketRiskReportRFSpecific };
}
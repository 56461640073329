import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { InformesTableCell, InformesTableRow } from '../../../components/CustomStyles';
import { useResponsive } from '../../../hooks/useResponsive';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import { useErrors } from '../../../hooks/useErrors';
import InLineError from '../../../components/InLineError';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { styled } from '@mui/material/styles';
import { GREY, DARK_GREY } from '../../../theme';

// Fila con estilo para totales
const StyledTotalRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        backgroundColor: GREY,
        fontWeight: 'bold',
    },
}));

export default function SummaryTable({executionYear, executionMonth, processId}) {
    const { isBelowXlScreen } = useResponsive();
    const { loading, inLineErrors, setInlineErrorState } = useErrors();
    const { getCreditRiskReportSummary } = reportsCreditRiskService();
    const formatNumber = useNumberFormatter();
    const [parameters, setParameters] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [totals, setTotals] = useState({});

    useEffect(() => {
        setInlineErrorState("getCreditRiskReportSummary", "Seleccione al menos una fecha de ejecución y una ejecución para poder visualizar los datos.");
        if (executionYear && executionMonth && processId) {
            fetchReportData();
        }
    }, [executionYear && executionMonth && processId]);

    const fetchReportData = async () => {
        const response = await getCreditRiskReportSummary({ year: executionYear, month: executionMonth, process_id: processId });
        if (response.current_values) {
            const { parameters, current_values } = response;

            // Ordenar parámetros según column_order
            const sortedParams = Object.keys(parameters).sort((a, b) => parameters[a].column_order - parameters[b].column_order);

            // Calcular totales por cada Epígrafe
            const totalsByCategory = calculateTotals(current_values);

            // Ordenar filas por Epígrafe y luego por Categoría
            const sortedData = current_values.sort((a, b) => {
                if (a.Epigrafe === b.Epigrafe) {
                    return a.id_categoria_exposicion.localeCompare(b.id_categoria_exposicion);
                }
                return a.Epigrafe.localeCompare(b.Epigrafe);
            });

            setParameters(sortedParams.map(param => ({ key: param, name: parameters[param].name })));
            setReportData(sortedData);
            setTotals(totalsByCategory);
        }
    };

    // Función para calcular totales por cada Epígrafe
    const calculateTotals = (data) => {
        const categories = [...new Set(data.map(item => item.Epigrafe))];  // Obtener categorías únicas
        const totals = {};

        categories.forEach(category => {
            const filteredData = data.filter(item => item.Epigrafe === category);
            totals[category] = filteredData.reduce((acc, curr) => {
                Object.keys(curr).forEach(key => {
                    if (typeof curr[key] === 'number') {
                        acc[key] = (acc[key] || 0) + curr[key];
                    }
                });
                return acc;
            }, {});
            totals[category]['Epigrafe'] = `${category} TOTAL`;  // Añadir el nombre del total
        });

        return totals;
    };
    
    const getCellAlignment = (value) => {
        return typeof value === 'number' ? 'right' : 'left';
    };

    if (loading.getCreditRiskReportSummary) {
        return <LoadingSpinner />;
    } else if (inLineErrors.getCreditRiskReportSummary) {
        return <InLineError width={"100%"} length={"100%"} errorMessage={inLineErrors.getCreditRiskReportSummary} />;
    } else {
        return (
            <TableContainer
                component={Paper}
                sx={{ boxShadow: 'none', mt: '7px', overflowX: isBelowXlScreen ? 'auto' : 'hidden' }}
            >
                <Table size='small' sx={{ borderCollapse: 'collapse', minWidth: '1250px', tableLayout: 'auto' }}>
                    <TableHead>
                        <InformesTableRow>
                            {parameters.map((param, index) => (
                                <InformesTableCell key={index} sx={{ textAlign: 'left', minWidth: `${param.name.length}ch` }}>
                                    {param.name}
                                </InformesTableCell>
                            ))}
                        </InformesTableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.length === 0 ? (
                            <InformesTableRow>
                                <InformesTableCell colSpan={parameters.length} sx={{ textAlign: 'center' }}>
                                    No hay datos para mostrar
                                </InformesTableCell>
                            </InformesTableRow>
                        ) : (
                            <>
                                {Object.keys(totals).map((category, categoryIndex) => (
                                    <React.Fragment key={categoryIndex}>
                                        {/* Filas de datos por categoría */}
                                        {reportData
                                            .filter(row => row.Epigrafe === category)
                                            .map((row, rowIndex) => (
                                                <InformesTableRow key={rowIndex}>
                                                    {parameters.map((param, idx) => (
                                                        <InformesTableCell key={idx} sx={{ textAlign: getCellAlignment(row[param.key]) }}>
                                                            {typeof row[param.key] === 'number'
                                                                ? formatNumber(row[param.key])  // Formatear si es número
                                                                : row[param.key]  // Mostrar si es texto
                                                            }
                                                        </InformesTableCell>
                                                    ))}
                                                </InformesTableRow>
                                            ))}
                                        {/* Fila de total para la categoría */}
                                        <StyledTotalRow key={`total-${categoryIndex}`}>
                                            {parameters.map((param, idx) => (
                                                <InformesTableCell key={idx} sx={{ textAlign: getCellAlignment(totals[category][param.key]) }}>
                                                    {typeof totals[category][param.key] === 'number'
                                                        ? formatNumber(totals[category][param.key])  // Formatear si es número
                                                        : totals[category][param.key] || ""  // Mostrar si es texto o vacío
                                                    }
                                                </InformesTableCell>
                                            ))}
                                        </StyledTotalRow>
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function executionSummaryService() {
    const { apiCall, addQueryParameters } = useApi();
    const { startLoading, stopLoading, setDialogErrorState, clearDialogError } = useErrors();

    const postExecutionSummaryCreditCounterpartyRisk = async ({ params, payload }) => {
        const key = 'postExecutionSummaryCreditCounterpartyRisk';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/execution/summary/credit-counterparty-risk");
        fileUrl = addQueryParameters(fileUrl, params);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('POST', fileUrl, payload);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error comprobando los campos del resumen de la ejecución.");
                Sentry.captureException(response, {
                    tags: { service: 'executionSummaryService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error comprobando los campos del resumen de la ejecución.");
            Sentry.captureException(error, {
                tags: { service: 'executionSummaryService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    const postExecutionSummaryMarketRisk = async ({ params, payload }) => {
        const key = 'postExecutionSummaryMarketRisk';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/execution/summary/market-risk");
        fileUrl = addQueryParameters(fileUrl, params);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('POST', fileUrl, payload);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error comprobando los campos del resumen de la ejecución.");
                Sentry.captureException(response, {
                    tags: { service: 'executionSummaryService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error comprobando los campos del resumen de la ejecución.");
            Sentry.captureException(error, {
                tags: { service: 'executionSummaryService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    const postExecutionSummaryOperationalRisk = async ({ params, payload }) => {
        const key = 'postExecutionSummaryOperationalRisk';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/execution/summary/operational-risk");
        fileUrl = addQueryParameters(fileUrl, params);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('POST', fileUrl, payload);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error comprobando los campos del resumen de la ejecución.");
                Sentry.captureException(response, {
                    tags: { service: 'executionSummaryService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error comprobando los campos del resumen de la ejecución.");
            Sentry.captureException(error, {
                tags: { service: 'executionSummaryService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    return { postExecutionSummaryCreditCounterpartyRisk, postExecutionSummaryMarketRisk, postExecutionSummaryOperationalRisk }
}
import { createContext, useState } from 'react'

// Este es el que tenemos que consumir
export const ProcessesConfigContext = createContext()

// Este es el que nos provee de acceso al contexto
export function ProcessesConfigProvider ({ children }) {
    const [configSubSections, setConfigSubSections] = useState({});
    const [currentConfig, setCurrentConfig] = useState({});

  return (
    <ProcessesConfigContext.Provider value={{
        configSubSections,
        setConfigSubSections,
        currentConfig,
        setCurrentConfig
    }}
    >
      {children}
    </ProcessesConfigContext.Provider>
  )
}
import { React, useState, useEffect, useRef } from 'react';
import { Box, Typography, Tabs, Dialog, DialogActions, DialogContent, 
    DialogTitle, Button, IconButton, Menu, MenuItem, Collapse } from '@mui/material';
import { HelpOutlineOutlined, Cancel, FileDownloadOutlined, Menu as MenuIcon } from '@mui/icons-material';
import { AntTab } from '../../../components/CustomStyles';
import { BLUE, BLACK, CONTENT_BOX, TITLE_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import dayjs from 'dayjs';
import { reportsMarketRiskService } from '../../../services/reportsMarketRiskService';
import { useProcesses } from '../../../hooks/useProcesses';
import { useProcessFilters } from '../../../hooks/useProcessFilters';
import { useUsers } from '../../../hooks/useUsers';
import SettingsMenu from '../SettingsMenu';
import RFSpecificTable from './RFSpecificTable';
import ChangeTypeTable from './ChangeTypeTable';
import RawMaterialsTable from './RawMaterialsTable';
import RVGeneralTable from './RVGeneralTable';
import RVSpecificTable from './RVSpecificTable';
import RFGeneralTable from './RFGeneralTable';



const menuOptions = [
    'Tipo Cambio',
    'Materias Primas',
    'RV General',
    'RV Específico',
    'RF General',
    'RF Específico',
]

const normativeVersionsList = [
    'CRR3'
];


export default function MarketRiskReport() {
    const { isBelowXlScreen } = useResponsive();
    const { getExcelFile } = reportsMarketRiskService();
    const { filterProcesses } = useProcesses();
    const { filters, fullDates, handleOnChangeFilter } = useProcessFilters({ defaultProcessType: "Riesgo de Mercado", defaultState: "Completado", defaultIncludeComments: "False" });
    const [selectedExecution, setSelectedExecution] = useState("");
    const { usersList } = useUsers();
    const [executionsList, setExecutionsList] = useState([]);
    const [tab, setTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const isFirstRender = useRef(true); // Ref para controlar el primer render

    useEffect(() => {
        fetchInitialProcessData();
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // Cambia el flag después del primer render
        } else {
            fetchFilteredExecutions(); // Ejecuta solo después del primer render
        }
    }, [filters]);

    const fetchInitialProcessData = async () => {
        try {
            // Buscar el último proceso de Riesgo de Crédito y Contraparte ejecutado con estado Completado para mostrarlo cuando se inicia el componente
            const auxExecutionsList = (await filterProcesses({ filters: filters, limit: 1 })).processesList;
            if (auxExecutionsList && auxExecutionsList.length > 0) {
                const auxSelectedExecution = auxExecutionsList[0];
                handleOnChangeFilter("userName", auxSelectedExecution.user);
                handleOnChangeFilter("normativeVersion", auxSelectedExecution.version);
                handleOnChangeFilter("DataStart", dayjs(auxSelectedExecution.data_date + "-01"));
                handleOnChangeFilter("ExecutionStart", dayjs(auxSelectedExecution.execution_year + "-" + auxSelectedExecution.execution_month + "-01"));
                setSelectedExecution(auxSelectedExecution.process_id);
                setExecutionsList([auxSelectedExecution.process_id]);
            }
        } catch (error) {
            console.error("Error fetching process data:", error);
        }
    };

    const fetchFilteredExecutions = async () => {
        try {
            const auxExecutionsList = (await filterProcesses({ filters: filters, limit: 100 })).processesList;
            
            if (auxExecutionsList && auxExecutionsList.length > 0) {
                selectedExecution === "" && setSelectedExecution(auxExecutionsList[0].process_id);
                setExecutionsList(auxExecutionsList.map(execution => execution.process_id));
            }
            else {
                setExecutionsList([]);
            }
        } catch (error) {
            console.error("Error fetching process data:", error);
        }
    };

    const handleOnChangeSelectedExecution = (value) => {
        setSelectedExecution(value);
    };

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setTab(index);
        handleMenuClose();
    };

    const changeFilter = (name, value) => {
        setSelectedExecution("");
        handleOnChangeFilter(name, value);
    };


    return (
        <>
            <Box
                sx={CONTENT_BOX}
            >
                <Box display={'flex'} justifyContent={'space-between'} >
                    <Box display={'flex'} >
                        <Typography
                            sx={{...TITLE_TEXT, mt: '9px'}}
                            color={BLUE}
                        >
                            Riesgo de Mercado
                        </Typography>
                        <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleClickOpenDialog}>
                            <HelpOutlineOutlined />
                        </IconButton>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            maxWidth='lg'
                            fullWidth
                        >
                            <DialogTitle
                                color={BLUE}
                                sx={TITLE_TEXT}
                            >
                                Riesgo de Mercado
                            </DialogTitle>
                            <DialogContent >
                                {"Fichero donde se incluye el detalle del cálculo de requisitos de capital por riesgo de mercado:"}{' '}<br />
                            </DialogContent>
                            <DialogActions
                                sx={{ mb: '20px', justifyContent: 'center' }}
                            >
                                <Button
                                    variant='contained'
                                    sx={{ fontSize: '13px', textTransform: 'none' }}
                                    endIcon={<Cancel />}
                                    onClick={handleCloseDialog}
                                >
                                    Cerrar
                                </Button>
                            </DialogActions>
                        </Dialog >
                    </Box>
                    <Button
                        variant='contained'
                        endIcon={<FileDownloadOutlined />}
                        sx={{ fontSize: '13px', textTransform: 'none' }}
                        onClick={() => getExcelFile({ year: filters.yearExecutionStart, month: filters.monthExecutionStart, process_id: selectedExecution })}
                        download={true}
                    >
                        Exportar Excel
                    </Button>
                </Box>
                <SettingsMenu
                    filters={filters}
                    fullDates={fullDates}
                    usersList={usersList}
                    selectedExecution={selectedExecution}
                    executionsList={executionsList}
                    changeFilter={changeFilter}
                    handleOnChangeSelectedExecution={handleOnChangeSelectedExecution}
                    normativeVersionsList={normativeVersionsList}
                />
            </Box>
            {!isBelowXlScreen &&
            (
                <Box display={'flex'}>
                    <Tabs 
                        sx={{ml: '66px', mb: '0px', minHeight: "38px", height: "38px"}} 
                        value={tab} 
                        onChange={handleTabChange}
                    >
                         {menuOptions.map((option, index) => (
                            <AntTab 
                                key={index}
                                tab={index} 
                                label={option}
                            />
                        ))}
                    </Tabs>
                </Box>
            )}
                <Box sx={{...CONTENT_BOX, mt: '0px'}}>
                    {isBelowXlScreen && (
                        <Box display={'flex'}>
                            <Typography
                                    sx={{...TITLE_TEXT, mt: '9px'}}
                                    color={BLUE}
                                >
                                    {menuOptions[tab]}
                            </Typography>
                            <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {menuOptions.map((option, index) => (
                                    <MenuItem 
                                        onClick={() => handleMenuItemClick(index)}
                                        sx={{ color: tab === index ? BLUE : BLACK }}
                                        key={index} // Usar un key único
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                            
                        </Box>
                    )}
                    {tab === 0 && (
                        <ChangeTypeTable
                            executionYear={filters.yearExecutionStart} 
                            executionMonth={filters.monthExecutionStart}
                            processId={selectedExecution}
                        />
                    )}
                    {tab === 1 && (
                        <RawMaterialsTable
                            executionYear={filters.yearExecutionStart} 
                            executionMonth={filters.monthExecutionStart}
                            processId={selectedExecution}
                        />
                    )}
                    {tab === 2 && (
                        <RVGeneralTable 
                            executionYear={filters.yearExecutionStart} 
                            executionMonth={filters.monthExecutionStart}
                            processId={selectedExecution}
                        />
                    )}
                    {tab === 3 && (
                        <RVSpecificTable 
                            executionYear={filters.yearExecutionStart} 
                            executionMonth={filters.monthExecutionStart}
                            processId={selectedExecution}
                        />
                    )}
                    {tab === 4 && (
                        <RFGeneralTable 
                            executionYear={filters.yearExecutionStart} 
                            executionMonth={filters.monthExecutionStart}
                            processId={selectedExecution}
                        />
                    )}
                    {tab === 5 && (
                        <RFSpecificTable 
                            executionYear={filters.yearExecutionStart} 
                            executionMonth={filters.monthExecutionStart}
                            processId={selectedExecution}
                        />
                    )}
                </Box>
        </>
    );
}
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Checkbox, FormControl, MenuItem, Select } from '@mui/material';
import { SELECT_TEXT } from '../theme'; 


const inputProps = {
    sx: {
        ... {
            padding: '4px',
            paddingLeft: '14px',
            fontSize: '14px',
            fontWeight: 400,
        }
    }
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '245px',
        },
    },
};

// CustomSelect.PropTypes = {
//     horizontal: PropTypes.bool,

// }

export default function CustomSelect(props) {
    const display = props.horizontal ? 'flex' : 'block'
    const headerMarginTop = props.horizontal ? '10px' : 0;
    const headerMarginRight = props.horizontal ? '12px' : 0;
    const selectMarginTop = props.horizontal ? '3px' : '6px';

    const renderValue = (selected) => {
        if (!selected || selected.length === 0) {
            return "Seleccione una opción";
        }

        if (props.multiple) {
            return selected.join(', ');
        }

        return selected;
    }

    return (
        <Box display={display}>
            <Typography sx={{ ...SELECT_TEXT, mt: headerMarginTop, mr: headerMarginRight }}>
                {props.title}
            </Typography>
            <FormControl fullWidth >
                <Select
                    name={props.name}
                    multiple={props.multiple}
                    value={props.value}
                    onChange={props.onChange}
                    displayEmpty
                    renderValue={renderValue}
                    size='small'
                    color='primary'
                    sx={{ fontWeight: 400, mt: selectMarginTop, minWidth: props.width, maxWidth: props.maxWidth, backgroundColor: 'white', }}
                    inputProps={inputProps}
                    MenuProps={MenuProps}
                >
                    {
                        props.selectItems.map(elem =>
                            <MenuItem
                                key={elem}
                                value={elem}
                                sx={{ fontSize: '14px', fontWeight: 400, padding: '4px', pl: '14px', pr: '14px' }}
                            >
                                {props.multiple && (
                                    <Checkbox
                                        size='small'
                                        sx={{ height: '14px', pl: 0 }}
                                        checked={props.value.indexOf(elem) > -1} />
                                )}
                                {elem}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </Box>
    );
}
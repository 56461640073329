import { React, useState, useEffect, useRef } from 'react';
import { Box, Typography, Tabs, Dialog, DialogActions, DialogContent, 
    DialogTitle, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { HelpOutlineOutlined, Cancel, FileDownloadOutlined, Menu as MenuIcon } from '@mui/icons-material';
import { AntTab } from '../../../components/CustomStyles';
import SummaryTable from './SummaryTable';
import SummaryByEntityTable from './SummaryByEntityTable';
import { BLUE, BLACK, CONTENT_BOX, TITLE_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import dayjs from 'dayjs';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import { useProcesses } from '../../../hooks/useProcesses';
import { useProcessFilters } from '../../../hooks/useProcessFilters';
import { useUsers } from '../../../hooks/useUsers';
import SettingsMenu from '../SettingsMenu';


const menuOptions = [
    'Resumen',
    'Resumen por entidad',
]

const normativeVersionsList = [
    'CRR2',
    'CRR3'
];


export default function CreditRiskReport() {
    const { isBelowXlScreen } = useResponsive();
    const { getExcelFile } = reportsCreditRiskService();
    const { filterProcesses } = useProcesses();
    const { filters, fullDates, handleOnChangeFilter } = useProcessFilters({ defaultProcessType: "Riesgo de Crédito y Contraparte", defaultState: "Completado", defaultIncludeComments: "False" });
    const [selectedExecution, setSelectedExecution] = useState("");
    const { usersList } = useUsers();
    const [executionsList, setExecutionsList] = useState([]);
    const [tab, setTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const isFirstRender = useRef(true); // Ref para controlar el primer render

    useEffect(() => {
        fetchInitialProcessData();
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // Cambia el flag después del primer render
        } else {
            fetchFilteredExecutions(); // Ejecuta solo después del primer render
        }
    }, [filters]);

    const fetchInitialProcessData = async () => {
        try {
            // Buscar el último proceso de Riesgo de Crédito y Contraparte ejecutado con estado Completado para mostrarlo cuando se inicia el componente
            const auxExecutionsList = (await filterProcesses({ filters: filters, limit: 1 })).processesList;
            if (auxExecutionsList && auxExecutionsList.length > 0) {
                const auxSelectedExecution = auxExecutionsList[0];
                handleOnChangeFilter("userName", auxSelectedExecution.user);
                handleOnChangeFilter("normativeVersion", auxSelectedExecution.version);
                handleOnChangeFilter("DataStart", dayjs(auxSelectedExecution.data_date + "-01"));
                handleOnChangeFilter("ExecutionStart", dayjs(auxSelectedExecution.execution_year + "-" + auxSelectedExecution.execution_month + "-01"));
                setSelectedExecution(auxSelectedExecution.process_id);
                setExecutionsList([auxSelectedExecution.process_id]);
            }
        } catch (error) {
            console.error("Error fetching process data:", error);
        }
    };

    const fetchFilteredExecutions = async () => {
        try {
            const auxExecutionsList = (await filterProcesses({ filters: filters, limit: 100 })).processesList;
            
            if (auxExecutionsList && auxExecutionsList.length > 0) {
                selectedExecution === "" && setSelectedExecution(auxExecutionsList[0].process_id);
                setExecutionsList(auxExecutionsList.map(execution => execution.process_id));
            }
            else {
                setExecutionsList([]);
            }
        } catch (error) {
            console.error("Error fetching process data:", error);
        }
    };

    const handleOnChangeSelectedExecution = (value) => {
        setSelectedExecution(value);
    };

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setTab(index);
        handleMenuClose();
    };

    const changeFilter = (name, value) => {
        setSelectedExecution("");
        handleOnChangeFilter(name, value);
    };


    return (
        <>
            <Box
                sx={CONTENT_BOX}
            >
                <Box display={'flex'} justifyContent={'space-between'} >
                    <Box display={'flex'} >
                        <Typography
                            sx={{...TITLE_TEXT, mt: '9px'}}
                            color={BLUE}
                        >
                            Riesgo de Crédito y Contraparte
                        </Typography>
                        <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleClickOpenDialog}>
                            <HelpOutlineOutlined />
                        </IconButton>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            maxWidth='lg'
                            fullWidth
                        >
                            <DialogTitle
                                color={BLUE}
                                sx={TITLE_TEXT}
                            >
                                Riesgo de Crédito y Contraparte
                            </DialogTitle>
                            <DialogContent >
                                {"Fichero donde se incluye el detalle de Riesgo de Crédito tanto a nivel grupal como granular, en concreto:"}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Resumen:"}</u> {" Resultados agrupados por epígrafe, categoría y ponderación donde se puede consultar la exposición, cobertura y APRs obtenidos."}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Resumen entidad:"}</u> {" Resultados agrupados por entidad, epígrafe, categoría y ponderación donde se puede consultar la exposición, cobertura y APRs obtenidos."}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Renta variable, Renta fija, Derivados, Inversión crediticia, Resto:"}</u> {" Resultados desglosados a nivel de contrato y cuenta contable, donde además de exposición, cobertura y APRs se pueden consultar atributos a nivel de operación para obtener una justificación de la asignación de categoría y ponderación obtenida."}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Diferencias:"}</u> {" Contratos con categorías y/o ponderaciones distintas entre este mes y el anterior."}{' '}<br />
                            </DialogContent>
                            <DialogActions
                                sx={{ mb: '20px', justifyContent: 'center' }}
                            >
                                <Button
                                    variant='contained'
                                    sx={{ fontSize: '13px', textTransform: 'none' }}
                                    endIcon={<Cancel />}
                                    onClick={handleCloseDialog}
                                >
                                    Cerrar
                                </Button>
                            </DialogActions>
                        </Dialog >
                    </Box>
                    <Button
                        variant='contained'
                        endIcon={<FileDownloadOutlined />}
                        sx={{ fontSize: '13px', textTransform: 'none' }}
                        onClick={() => getExcelFile({ year: filters.yearExecutionStart, month: filters.monthExecutionStart, process_id: selectedExecution })}
                        download={true}
                    >
                        Exportar Excel
                    </Button>
                </Box>
                <SettingsMenu
                    filters={filters}
                    fullDates={fullDates}
                    usersList={usersList}
                    selectedExecution={selectedExecution}
                    executionsList={executionsList}
                    changeFilter={changeFilter}
                    handleOnChangeSelectedExecution={handleOnChangeSelectedExecution}
                    normativeVersionsList={normativeVersionsList}
                />
            </Box>
            {!isBelowXlScreen &&
            (
                <Box display={'flex'}>
                    <Tabs 
                        sx={{ml: '66px', mb: '0px', minHeight: "38px", height: "38px"}} 
                        value={tab} 
                        onChange={handleTabChange}
                    >
                        <AntTab 
                            tab={0}
                            label="Resumen" 
                        />
                        <AntTab 
                            tab={1}
                            label="Resumen por entidad" 
                        />
                    </Tabs>
                </Box>
            )}
                <Box sx={{...CONTENT_BOX, mt: '0px'}}>
                    {isBelowXlScreen && (
                        <Box display={'flex'}>
                            <Typography
                                    sx={{...TITLE_TEXT, mt: '9px'}}
                                    color={BLUE}
                                >
                                    {menuOptions[tab]}
                            </Typography>
                            <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem 
                                    onClick={() => handleMenuItemClick(0)}
                                    sx={{ color: tab === 0 ? BLUE : BLACK }}
                                >
                                    Resumen
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleMenuItemClick(1)}
                                    sx={{ color: tab === 1 ? BLUE : BLACK }}
                                >
                                    Resumen por entidad
                                </MenuItem>
                            </Menu>
                            
                        </Box>
                    )}
                    {tab === 0 && (
                        <SummaryTable
                            executionYear={filters.yearExecutionStart} 
                            executionMonth={filters.monthExecutionStart}
                            processId={selectedExecution}
                        />
                    )}
                    {tab === 1 && (
                        <SummaryByEntityTable
                        executionYear={filters.yearExecutionStart} 
                        executionMonth={filters.monthExecutionStart}
                        processId={selectedExecution}
                    />
                    )}
                </Box>
        </>
    );
}
// BarChart.js
import React from 'react';
import Chart from 'react-apexcharts';
import { COLOR_PALETTE } from '../../../theme';

const BarChart = ({
    categories = [""],
    series = [],
    title = '',
    xAxisTitle = '',
    yAxisTitle = '',
    width = '100%',
    height = '400px',
    stacked = false,
    orientation = 'vertical',
}) => {
    const options = {
        chart: {
            type: 'bar',
            stacked: stacked,
            stackType: '100%',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: orientation === 'horizontal',
                barHeight: '80%'
            }
        },
        xaxis: {
            categories: categories,
            title: {
                text: xAxisTitle,
            },
            labels: {
                formatter: function (value) {
                    return `${value}%`; // Format X-axis as percentage
                }
            }
        },
        yaxis: {
            title: {
                text: yAxisTitle,
            },
        },
        title: {
            text: title,
            align: 'center'
        },
        legend: {
            position: 'bottom'
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return `${val.toFixed(2)}%`;
            }
        },
        tooltip: {
            y: {
                formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                    // Obtener el total de la barra actual sumando los valores de la categoría
                    const total = w.config.series.reduce((acc, series) => acc + series.data[dataPointIndex], 0);
                    
                    // Calcular el porcentaje de la barra apilada actual
                    const percentage = ((val / total) * 100).toFixed(2);
    
                    // Mostrar el valor en euros y el porcentaje
                    return `${val.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })} (${percentage}%)`;
                }
            }
        },
        colors: COLOR_PALETTE
    };

    return (
        <Chart
            options={options}
            series={series.length ? series : [{ name: '', data: [0] }]} // Ensure series has a valid structure
            type="bar"
            width={width}
            height={height}
        />
    );
};

export default BarChart;


// this.state = {
          
//     series: [{
//       name: 'Marine Sprite',
//       data: [44, 55, 41, 37, 22, 43, 21]
//     }, {
//       name: 'Striking Calf',
//       data: [53, 32, 33, 52, 13, 43, 32]
//     }, {
//       name: 'Tank Picture',
//       data: [12, 17, 11, 9, 15, 11, 20]
//     }, {
//       name: 'Bucket Slope',
//       data: [9, 7, 5, 8, 6, 9, 4]
//     }, {
//       name: 'Reborn Kid',
//       data: [25, 12, 19, 32, 25, 24, 10]
//     }],
//     options: {
//       chart: {
//         type: 'bar',
//         height: 350,
//         stacked: true,
//         stackType: '100%'
//       },
//       plotOptions: {
//         bar: {
//           horizontal: true,
//         },
//       },
//       stroke: {
//         width: 1,
//         colors: ['#fff']
//       },
//       title: {
//         text: '100% Stacked Bar'
//       },
//       xaxis: {
//         categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
//       },
//       tooltip: {
//         y: {
//           formatter: function (val) {
//             return val + "K"
//           }
//         }
//       },
//       fill: {
//         opacity: 1
      
//       },
//       legend: {
//         position: 'top',
//         horizontalAlign: 'left',
//         offsetX: 40
//       }
//     },
  
  
//   };
// }

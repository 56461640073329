import { React } from 'react';
import { Box, Typography } from '@mui/material';
import { StyledDatePicker} from '../../components/CustomStyles';
import CustomSelect from '../../components/CustomSelect';
import { SELECT_TEXT } from '../../theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


export default function SettingsMenu ({ filters, selectedExecution, usersList, executionsList, changeFilter, handleOnChangeSelectedExecution, fullDates, normativeVersionsList }) {
    return (
        <Box 
            display={'flex'}
            justifyContent={'space-between'}
            sx={{ mt: '20px' }}
        >
            <Box
                display={'flex'}
            >
                <Typography sx={{ ...SELECT_TEXT, mt: '10px', mr: '12px', whiteSpace: 'nowrap' }}>
                    Fecha de datos
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledDatePicker
                        sx={{ minWidth: '145px' }}
                        views={['year', 'month']} 
                        name={"dataDate"}
                        value={fullDates.dateDataStart} 
                        onChange={(event) =>{console.log(event); changeFilter("DataStart", event)}}
                        closeOnSelect={true}
                        slotProps={{
                            actionBar: {
                                actions: ['clear']
                            }
                        }}
                    />
                </LocalizationProvider>
            </Box>
            
            <Box
                display={'flex'}
            >
                <Typography sx={{ ...SELECT_TEXT, mt: '10px', mr: '12px', whiteSpace: 'nowrap' }}>
                    Fecha de ejecución
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledDatePicker
                        sx={{ minWidth: '145px' }}
                        views={['year', 'month']} 
                        name={"executionDate"}
                        value={fullDates.dateExecutionStart} 
                        onChange={(event) => changeFilter("ExecutionStart", event)}
                        closeOnSelect={true}
                        slotProps={{
                            actionBar: {
                                actions: ['clear']
                            }
                        }}
                    />
                </LocalizationProvider>
            </Box>
            <Box
                display={'flex'}
            >
                <Typography sx={{ ...SELECT_TEXT, mt: '10px', mr: '12px', whiteSpace: 'nowrap' }}>
                    Versión normativa
                </Typography>
                <CustomSelect
                    width={'170px'}
                    selectItems={normativeVersionsList}
                    name={"normativeVersion"}
                    value={filters.normativeVersion} 
                    onChange={(event) => changeFilter("normativeVersion", event.target.value)}
                    horizontal
                />
            </Box>
            <Box
            >
                <CustomSelect
                    title={'Usuario'}
                    name={"userName"}
                    selectItems={usersList.map(user => user.userName)}
                    value={filters.userName}
                    onChange={(event) => changeFilter("userName", event.target.value)}
                    horizontal
                />
            </Box>
            <Box
            >
                <CustomSelect
                    title={'Ejecución'}
                    name={"execution"}
                    selectItems={executionsList}
                    value={selectedExecution}
                    onChange={(event) => handleOnChangeSelectedExecution(event.target.value)}
                    horizontal
                />
            </Box>
        </Box>
    );
}

import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function processSituationService() {
    const { apiCall, addQueryParameters } = useApi();
    const { startLoading, stopLoading, setDialogErrorState, clearDialogError } = useErrors();

    const putProcessSituation = async ({process_id, situation}) => {
        const key = 'putProcessSituation';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/update-situation/" + process_id);
        var queryParams = {situation: situation};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('PUT', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error al actualizar la situación del proceso.");
                Sentry.captureException(response, {
                    tags: { service: 'processSituationService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error al actualizar la situación del proceso.");
            Sentry.captureException(error, {
                tags: { service: 'processSituationService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    return { putProcessSituation }
}
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Autocomplete, TextField } from '@mui/material';


CustomAutocomplete.propTypes = {
    title: PropTypes.string,
    autocompleteItems: PropTypes.array,
    setSelectedValue: PropTypes.func,
    setInputValue: PropTypes.func,
    isLabelVisible: PropTypes.bool 
};

export default function CustomAutocomplete(props) {
    const { title, autocompleteItems, setSelectedValue, setInputValue, isLabelVisible } = props

    return (
        <Box display={'flex'} sx={{ width: '100%' }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 600, mt: '10px', mr: '12px' }}>
                {title}
            </Typography>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                color="primary"
                options={autocompleteItems}
                sx={{ fontWeight: 400, mt: '3px', width: '100%' }}
                onChange={(event, newValue) => {
                    setSelectedValue(newValue); // Update the selected value state
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue); // Update the input value state
                }}
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        label={isLabelVisible  ? "Seleccione una opción" : ''}
                        InputLabelProps={{
                            shrink: false, 
                            style: {
                                fontWeight: 400, 
                                fontSize: '14px',
                                lineHeight: '1',
                                color: 'black',
                                width: '69%',
                                '&.MuiFocused': {
                                    color: 'grey' 
                                }
                            }
                        }} 
                        style={{ height: '32px' }}
                        variant="outlined"
                        InputProps={{ 
                            ...params.InputProps, 
                            style: { 
                                paddingLeft: '4px', 
                                paddingTop: '4px', 
                                fontSize: '14px', 
                                fontWeight: 400,
                                height: '31px'
                            },
                            endAdornment: (
                                <React.Fragment>
                                {params.InputProps.endAdornment}
                                <style>
                                    {`.MuiAutocomplete-endAdornment .MuiSvgIcon-root { color: grey; }`}
                                </style>
                                </React.Fragment>
                            )
                        }}
                    />}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option} style={{ fontSize: '14px' }}> 
                        {option}
                    </li>
                )}
            />
        </Box>
    );
}


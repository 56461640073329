import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BLUE, DARK_GREY, GREY, SUBTITLE_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import { cdmCreditRiskService } from '../../../services/cdmCreditRisk';
import CustomSelect from '../../../components/CustomSelect';
import { useErrors } from '../../../hooks/useErrors';
import LoadingSpinner from '../../../components/LoadingSpinner';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-root': {
        fontSize: '13px',
        padding: 0,
    },
    '&.MuiTableCell-head': {
        fontWeight: 700,
        borderBottom: `solid 1px ${BLUE}`,
    },
    '&.MuiTableCell-body': {
        padding: '4px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-body': {
        border: `solid 2px ${DARK_GREY}`
    },
    '&:last-child td': {
        fontWeight: 700,
        backgroundColor: GREY,
    },
}));

const displayIndicators = ["Exposición Original", "APRs"]
export default function RiskPonderationEADsTable({ parameters, usedPonderations, totals }) {
    const { isBelowXlScreen } = useResponsive();
    const { loading, inLineErrors } = useErrors();
    const [selectedIndicator, setSelectedIndicator] = useState("Exposición Original");
    const formatNumber = (value) => value.toLocaleString("es-ES", { minimumFractionDigits: 0, maximumFractionDigits: 2 });

    const handleOnChangeDisplayIndicator = (event) => {
        setSelectedIndicator(event.target.value);
    }

    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{ mt: '30px' }}
            >
                <Grid
                    item
                    xs={11}
                    sm={11}
                    md={9}
                    lg={8}
                    xl={9}
                >
                    <Typography
                        color={BLUE}
                        sx={SUBTITLE_TEXT}
                    >
                        EADs y ponderaciones de riesgo de cada categoría regulatoria
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1}
                    sm={1}
                    md={4}
                    lg={4}
                    xl={3}
                >
                    <CustomSelect
                        title={'Indicador:'}
                        selectItems={displayIndicators}
                        value={selectedIndicator}
                        onChange={handleOnChangeDisplayIndicator}
                        horizontal
                    />
                </Grid>

            </Grid>
            {loading.getCdMCreditRiskCategoriesPonderations ? <LoadingSpinner /> : (
                <TableContainer 
                    component={Paper}
                    sx={{ overflowX: isBelowXlScreen ? 'auto' : 'hidden'}}
                >
                    <Table sx={{ minWidth: '1400px' }}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell rowSpan={2} align='center' width={"20%"}>
                                    Categorías de exposición
                                </StyledTableCell >
                                <StyledTableCell colSpan={usedPonderations && usedPonderations.length} align='center' width={"70%"}>
                                    Ponderación de riesgo
                                </StyledTableCell>
                                <StyledTableCell rowSpan={2} align='center' width={"5%"}>
                                    Total
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                {usedPonderations && usedPonderations.map((ponderation) => (
                                    <StyledTableCell
                                        key={ponderation}
                                        align='center'
                                        width={`${70 / usedPonderations.length}%`}
                                    >
                                        {ponderation}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {parameters && parameters.map((row, rowIndex) => (
                                <StyledTableRow key={rowIndex}>
                                    <StyledTableCell>{row.category_name}</StyledTableCell>
                                    {usedPonderations.map((ponderation, colIndex) => (
                                        <StyledTableCell key={colIndex} align='center'>
                                            {row.values[ponderation] ?
                                                row.values[ponderation][selectedIndicator] !== 0 ? formatNumber(row.values[ponderation][selectedIndicator]) : '-'
                                                : '-'
                                            }
                                        </StyledTableCell>
                                    ))}
                                    <StyledTableCell align='center'>
                                        {row.total ? 
                                            row.total[selectedIndicator] !== 0 ? formatNumber(row.total[selectedIndicator]) : '-' : '-'}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                            <StyledTableRow>
                                <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: GREY }}>
                                    Total
                                </StyledTableCell>
                                {usedPonderations && usedPonderations.map((ponderation, index) => (
                                    <StyledTableCell key={index} align='center' style={{ fontWeight: 'bold', backgroundColor: GREY }}>
                                        {totals[ponderation] 
                                            ? totals[ponderation][selectedIndicator] !== 0 ? formatNumber(totals[ponderation][selectedIndicator]) : '-'
                                            : '-'}
                                    </StyledTableCell>
                                ))}
                                <StyledTableCell align='center' style={{ fontWeight: 'bold', backgroundColor: GREY }}>
                                    {totals && totals.total ? totals.total[selectedIndicator] !== 0 ? formatNumber(totals.total[selectedIndicator]) : '-' : '-'}
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}

import { React, useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { BLUE, SUBTITLE_TEXT, } from '../../theme';
import CustomSelect from '../../components/CustomSelect';
import { useResponsive } from '../../hooks/useResponsive';
import RiskPonderationEADsTable from './tables/RiskPonderationEADsTable';
import { cdmCreditRiskService } from '../../services/cdmCreditRisk';
import { useErrors } from '../../hooks/useErrors';
import CategoryIndicatorsDonutChart from './CategoryIndicatorsDonutChart';
import CategoriesIndicatorsBarChart from './CategoriesIndicatorsBarChart';




export default function TabCreditCounterpartyRisk({ }) {    
    const { isBelowMdScreen } = useResponsive();
    const { getCdMCreditRiskCategoriesPonderations } = cdmCreditRiskService();
    const [data, setData] = useState([]);

    
    const [expandedSettings, setExpandedSettings] = useState(false);

    useEffect(() => {
        fetchTableData();
    }, []);

    useEffect(() => {
        // Check if the screen is below xl and expandedSettings is true
        if (!isBelowMdScreen && expandedSettings) {
            // Set expandedSettings to false
            setExpandedSettings(false);
        }
    }, [isBelowMdScreen]);


    const fetchTableData = async () => {
        try {
            const tableData = await getCdMCreditRiskCategoriesPonderations({
                process_id: "cHVGLxGYdUxpKGkgaW4ENX",
                year: "10",
                month: "2024",
            });
            if (tableData) {
                setData(tableData);
            }
        } catch (error) {
            console.error("Error fetching process data:", error);
        }
    };
    
    

    return (
        <>
            <Box sx={{ width: '100%'}}>
                <CategoriesIndicatorsBarChart data={data} />
                <Box sx={{ width: '100%', ml: '0px', display: 'flex', gap: '100px' }}>
                    <CategoryIndicatorsDonutChart data={data} indicator={"APRs"} />
                    <CategoryIndicatorsDonutChart data={data} indicator={"Exposición Original"} />
                </Box>
            </Box>
            <Box sx={{ mt: '20px', width: '100%', height: '700px' }}>
                <RiskPonderationEADsTable parameters={data.parameters} usedPonderations={data.used_ponderations} totals={data.totals}/>
            </Box>
        </>
    );
}
import { React, useState } from 'react';
import { Box, Typography, Tabs} from '@mui/material';
import { AntTab } from '../../components/CustomStyles';
import { BLUE, CONTENT_BOX, TITLE_TEXT } from '../../theme';
import EntitiesConfig from './EntitiesConfig';
import ProcessesConfig from './ProcessesConfig';
import { ProcessesConfigProvider } from '../../context/ProcessesConfigContext';


const tabNames = ["Entidades", "Procesos"];

export default function Configuration() {
    const [tab, setTab] = useState(0);

    const handleChangeTab = (_event, value) => {
        setTab(value);
    };

    return (
        <>
            <Box
                sx={CONTENT_BOX}
            >
                <Typography
                    sx={TITLE_TEXT}
                    color={BLUE}
                >
                    Administración
                </Typography>
            </Box>
            <Box display={'flex'}>
                <Tabs 
                    sx={{ml: '59px', mb: '0px', minHeight: "38px", height: "38px"}} 
                    value={tab}
                    onChange={handleChangeTab}
                >
                    {tabNames.map((tabName, index) => 
                        <AntTab
                            key={index}
                            tab={index}
                            label={tabName}
                        />
                    )}
                </Tabs>
            </Box>
            <Box sx={{...CONTENT_BOX, mt: '0px'}}>
                
                {tab === 0 && <EntitiesConfig/>}
                {tab === 1 && <ProcessesConfigProvider><ProcessesConfig/></ProcessesConfigProvider>}
            </Box>
        </>
    );
}
import { useContext, useState } from 'react'
import { ProcessesContext } from '../context/ProcessesContext'

export const useProcessFilters = ({ defaultProcessType = "", defaultState = "", defaultIncludeComments = "True" } = {}) => {
    const { setAppliedFilters } = useContext(ProcessesContext)
    const [filters, setFilters] = useState({
        monthDataStart: null,
        yearDataStart: null,
        monthDataEnd: null,
        yearDataEnd: null,
        monthExecutionStart: null,
        yearExecutionStart: null,
        monthExecutionEnd: null,
        yearExecutionEnd: null,
        normativeVersion: "",
        processType: defaultProcessType,
        state: defaultState,
        userName: "",
        includeComments: defaultIncludeComments
    })
    const [fullDates, setFullDates] = useState({
        dateDataStart: null,
        dateDataEnd: null,
        dateExecutionStart: null,
        dateExecutionEnd: null,
    })

    const [openFilters, setOpenFilters] = useState(false)


    const handleOpenFilters = () => {
        setOpenFilters(!openFilters)
    }

    const handleClearFilters = () => {
        setFullDates({
            dateDataStart: null,
            dateDataEnd: null,
            dateExecutionStart: null,
            dateExecutionEnd: null,
        })
        setFilters({
            monthDataStart: null,
            yearDataStart: null,
            monthDataEnd: null,
            yearDataEnd: null,
            monthExecutionStart: null,
            yearExecutionStart: null,
            monthExecutionEnd: null,
            yearExecutionEnd: null,
            processType: "",
        })
        setAppliedFilters({})
    }

    const areAnyFilters = () => {
        return Object.entries(filters)
            .filter(([key]) => key !== 'includeComments')  // Excluye 'includeComments' de la comprobación
            .some(([, value]) => value !== null && value !== "");
    }
    const changeMonthStructure = (month) => {
        const changedMonth = String(month + 1).padStart(2, '0')
        return changedMonth
    }

    const handleOnChangeFilter = (name, value, event) => {
        if (name === "processType" || name === "userName" || name === "normativeVersion") {
            setFilters(prev => ({ ...prev, [name]: value }))
        }
        else {
            setFullDates(prev => ({ ...prev, ["date" + name]: value }))
            if (value) {
                setFilters(prev => ({ ...prev, ["month" + name]: changeMonthStructure(value.$M), ["year" + name]: value.$y }))
            } else {
                setFilters(prev => ({ ...prev, ["month" + name]: null, ["year" + name]: null }))
            }
        }
    }

    return {
        filters,
        fullDates,
        openFilters,
        handleOpenFilters,
        handleClearFilters,
        areAnyFilters,
        handleOnChangeFilter,
    }
}
import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function processCommentsService() {
    const { apiCall, addQueryParameters } = useApi();
    const { startLoading, stopLoading, setErrorState, clearError, setDialogErrorState, clearDialogError } = useErrors();

    const getComments = async (process_id) => {
        const key = 'getComment';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/get-comments");
        var queryParams = { process_id };
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('GET', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error encontrando los comentarios.");
                Sentry.captureException(response, {
                    tags: { service: 'processCommentsService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error encontrando los comentarios.");
            Sentry.captureException(error, {
                tags: { service: 'processCommentsService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const postComment = async (processId, newComment) => {
        const key = 'postComment';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/add-comment");
        var queryParams = {process_id: processId, comment: newComment};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('POST', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error guardando el comentario.");
                Sentry.captureException(response, {
                    tags: { service: 'processCommentsService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error guardando el comentario.");
            Sentry.captureException(error, {
                tags: { service: 'processCommentsService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'POST' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const putComment = async (updatedComment) => {
        const key = 'putComment';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/update-comment/" + updatedComment.comment_id);
        var queryParams = {comment: updatedComment.comment_desc};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('PUT', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error al actualizar el comentario.");
                Sentry.captureException(response, {
                    tags: { service: 'processCommentsService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error al actualizar el comentario.");
            Sentry.captureException(error, {
                tags: { service: 'processCommentsService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'PUT' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    const deleteComment = async (comment_id) => {
        const key = 'deleteComment';
        var fileUrl = new URL(window.REACT_APP_API_URL + "/delete-comment/" + comment_id);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await apiCall('DELETE', fileUrl);
            if (response.success) {
                return response.data;
            } else {
                setDialogErrorState(key, "Error eliminando el comentario.");
                Sentry.captureException(response, {
                    tags: { service: 'processCommentsService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'DELETE' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error eliminando el comentario.");
            Sentry.captureException(error, {
                tags: { service: 'processCommentsService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'DELETE' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    };

    return { getComments, postComment, putComment, deleteComment};
}

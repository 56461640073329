import React, { createContext, useState, useCallback } from 'react';

export const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
    const [loading, setLoading] = useState({});
    const [inLineErrors, setInLineErrors] = useState({});
    const [dialogErrors, setDialogErrors] = useState({});

    const startLoading = useCallback((key) => {
        setLoading(prev => ({ ...prev, [key]: true }));
    }, []);

    const stopLoading = useCallback((key) => {
        setLoading(prev => ({ ...prev, [key]: false }));
    }, []);

    const setInlineErrorState = useCallback((key, errorMessage) => {
        setInLineErrors(prev => ({ ...prev, [key]: errorMessage }));
    }, []);

    const setDialogErrorState = useCallback((key, errorMessage) => {
        setDialogErrors(prev => ({ ...prev, [key]: errorMessage }));
    }, []);

    const setErrorState = useCallback((key, errorMessage) => {
        setInlineErrorState(key, errorMessage);
        setDialogErrorState(key, errorMessage);
    }, [setInlineErrorState, setDialogErrorState]);

    const clearInlineError = useCallback((key) => {
        setInLineErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[key];
            return newErrors;
        });
    }, []);

    const clearDialogError = useCallback((key) => {
        setDialogErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[key];
            return newErrors;
        });
    }, []);

    const clearError = useCallback((key) => {
        clearInlineError(key);
        clearDialogError(key);
    }, [clearInlineError, clearDialogError]);

    const clearAllInlineErrors = useCallback(() => {
        setInLineErrors({});
    }, []);

    const clearAllDialogErrors = useCallback(() => {
        setDialogErrors({});
    }, []);

    const clearAllErrors = useCallback(() => {
        clearAllInlineErrors();
        clearAllDialogErrors();
    }, [clearAllInlineErrors, clearAllDialogErrors]);

    return (
        <ErrorContext.Provider 
            value={{
                loading, 
                inLineErrors, 
                dialogErrors, 
                startLoading, 
                stopLoading, 
                setInlineErrorState, 
                setDialogErrorState, 
                setErrorState, 
                clearInlineError, 
                clearDialogError, 
                clearError,
                clearAllInlineErrors, 
                clearAllDialogErrors, 
                clearAllErrors
            }}
        >
            {children}
        </ErrorContext.Provider>
    );
};

import React, { useState, useEffect } from 'react';
import DonutChart from './charts/DonutChart';
import { Box, Grid, Typography } from '@mui/material';
import { useErrors } from '../../hooks/useErrors';
import { BLUE, SUBTITLE_TEXT } from '../../theme';
import CustomSelect from '../../components/CustomSelect';
import LoadingSpinner from '../../components/LoadingSpinner';


const CategoryIndicatorsDonutChart = ({ data, indicator }) => {
    const { loading, inLineErrors } = useErrors();
    const [selectedCategory, setSelectedCategory] = useState("");
    const [seriesData, setSeriesData] = useState([]);
    const [labels, setLabels] = useState([]);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    useEffect(() => {
        if (selectedCategory === "" && data.parameters) {
            setSelectedCategory(data.parameters[0].category_name);
        }
        updateChartData();
    }, [selectedCategory, data]);

    const updateChartData = () => {
        
        const category = data.parameters ? data.parameters.find((param) => param.category_name === selectedCategory) : null
        if (category) {
            const newSeriesData = [];
            const newLabels = [];

            Object.entries(category.values).forEach(([ponderation, value]) => {
                newLabels.push(ponderation);
                newSeriesData.push(value[indicator]);
            });

            setSeriesData(newSeriesData);
            setLabels(newLabels);
        }
    };

    return (
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: '30px' }}
                >
                    <Grid
                        item
                        xs={11}
                        sm={11}
                        md={9}
                        lg={8}
                        xl={7}
                    >
                        <Typography
                            color={BLUE}
                            sx={SUBTITLE_TEXT}
                        >
                            Ponderación de {indicator} por Categoria
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        sm={1}
                        md={4}
                        lg={4}
                        xl={5}
                    >
                        <CustomSelect
                            title={'Categoría:'}
                            selectItems={data.parameters ? data.parameters.map((param) => (param.category_name)) : []}
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            horizontal
                        />
                    </Grid>

                </Grid>
                {loading.getCdMCreditRiskCategoriesPonderations ? <LoadingSpinner /> : (
                    <DonutChart
                        seriesData={seriesData}
                        labels={labels}
                    />
                )}
            </Box>
    );
};

export default CategoryIndicatorsDonutChart;

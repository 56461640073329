export const HOME_PATH = '/';
export const ENQUIRY_PATH = '/consulta';
export const PROCESSES_PATH = '/processes';
export const NEW_EXECUTION_PATH = '/processes/new-execution';
export const SIMULATIONS_PATH = '/simulations';
export const NEW_SIMULATION_PATH = '/simulations/new-simulation';
export const CDM_EXECUTIONS_PATH = '/cdm-executions';
export const REPORTS_CREDIT_RISK_PATH = '/reports/credit-risk';
export const REPORTS_MARKET_RISK_PATH = '/reports/market-risk';
export const REPORTS_OPERATIONAL_RISK_PATH = '/reports/operational-risk';
export const CONFIGURATION_PATH = '/configuracion';

// CategoriesIndicatorsBarChart.js
import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useErrors } from '../../hooks/useErrors';
import { BLUE, SUBTITLE_TEXT } from '../../theme';
import LoadingSpinner from '../../components/LoadingSpinner';
import BarChart from './charts/BarChart';

const CategoriesIndicatorsBarChart = ({ data }) => {
    const { loading } = useErrors();
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [seriesData, setSeriesData] = useState([]);

    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.getBoundingClientRect().width || 400); // Set a default value if width is 0
        }

        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.getBoundingClientRect().width || 400);
            }
        };
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        updateChartData();
    }, [data]);

    const updateChartData = () => {
        if (data && data.parameters && data.totals && data.totals.total) {
            const series = data.parameters.map((parameter) => {
                return {
                    name: parameter.category_name,
                    data: [
                    parameter.total["Exposición Original"] || 0,
                    parameter.total["APRs"] || 0
                    ]
                };
            });

            setSeriesData(series);
        }
    };

    return (
        <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'column'}}>
            <Typography
                color={BLUE}
                sx={SUBTITLE_TEXT}
            >
                Exposición Original Total y APRs Total por Categoría
            </Typography>
            {loading.getCdMCreditRiskCategoriesPonderations && data ? <LoadingSpinner /> : (
                <BarChart
                    categories={["Exposición Original", "APRs"]}
                    series={seriesData}                    
                    width={`${containerWidth}px`}
                    height="200px"
                    stacked={true}
                    orientation={"horizontal"}
                />
            )}
        </Box>
    );
};

export default CategoriesIndicatorsBarChart;

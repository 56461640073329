import { useContext } from 'react';
import { processCommentsService } from "../services/processCommentsService";
import { ProcessesContext } from '../context/ProcessesContext';

export const useProcessComments = () => {
    const { processesList, setProcessesList } = useContext(ProcessesContext)    
    const { getComments, postComment, putComment, deleteComment } = processCommentsService();

    const refreshComments = async (process_id) => {
        const data = await getComments(process_id);
        if (data) {
            setProcessesList(processesList.map(process => 
                process.process_id === process_id ? { ...process, comments: data.comments } : process
            ));
        }
    };

    const storeComment = async(processId, newComment) => {
        await postComment(processId, newComment);
    };

    const updateComment = async(currentComment) => {
        await putComment(currentComment);
    };

    const eliminateComment = async(comment_id) => {
        await deleteComment(comment_id);
    };

    return {
        storeComment,
        updateComment,
        eliminateComment,
        refreshComments
    };
};
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardActionArea, CardMedia, Divider, Grid, IconButton, Menu, Box,
         MenuItem, Stack, Typography, Drawer, List, ListItemText, Collapse, ListItemButton } from '@mui/material';
import { AccountCircle, ArrowDropDownOutlined, Notifications, ArrowRight, ExpandMore, 
         ExpandLess, Menu as MenuIcon, Settings } from '@mui/icons-material';
import { BLACK, BLUE } from '../theme';
import { useResponsive } from '../hooks/useResponsive';
import { PROCESSES_PATH, NEW_EXECUTION_PATH,
         CDM_EXECUTIONS_PATH, REPORTS_CREDIT_RISK_PATH, 
         CONFIGURATION_PATH,
         REPORTS_OPERATIONAL_RISK_PATH,
         REPORTS_MARKET_RISK_PATH} from '../config/Paths';
import { useAuth } from '../hooks/useAuth';



const MENU_STYLE = {
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: 400
}

const Logo = () => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(PROCESSES_PATH);
    };

    return (
        <Card sx={{ boxShadow: 'none', maxWidth: 250, ml: '50px' }}>
            <CardActionArea onClickCapture={handleOnClick}>
                <CardMedia>
                    <Stack
                        sx={{mt: '4px' }}
                        direction="row"
                        spacing={1}
                        divider={
                            <Divider
                                orientation="vertical"
                                flexItem
                            />
                        }
                    >
                        <img src={"/logo.png"} alt={"Alamoreg - Solvencia"} style={{ height: 30, marginTop: 4 }} />
                        <Typography sx={{ fontSize: '25px' }}>Solvencia</Typography>
                    </Stack>
                </CardMedia>
            </CardActionArea>
        </Card>
    );
}




const ExecutionMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleOnClickMenu = (event) => {
        navigate(PROCESSES_PATH);
    };


    const isSelectedMainMenu = () => location.pathname === PROCESSES_PATH
        || location.pathname === NEW_EXECUTION_PATH


    return (
        <>
            <Button
                sx={{ ...MENU_STYLE, color: isSelectedMainMenu() ? BLUE : BLACK }}
                onClick={handleOnClickMenu}
            >
                Procesos
            </Button>
        </>
    );
}

const CDMExecutions = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleOnClickMenu = (event) => {
        navigate(CDM_EXECUTIONS_PATH);
    };

    const isSelectedMainMenu = () => location.pathname === CDM_EXECUTIONS_PATH;

    return (
        <Button
            sx={{ ...MENU_STYLE, color: isSelectedMainMenu() ? BLUE : BLACK }}
            onClick={handleOnClickMenu}
        >
            CdM Ejecuciones
        </Button>
    );
}


const ReportsMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [dropdown, setDropdown] = useState(null);

    const handleOnClickMenu = (event) => {
        setDropdown(event.currentTarget);
    };

    const handleOnClickSubmenu = (path) => () => {
        setDropdown(null);
        navigate(path);
    };

    const handleOnCloseSubmenu = () => {
        setDropdown(null);
    };

    const isOpen = () => dropdown !== null;

    const isSelectedMainMenu = () => location.pathname === REPORTS_CREDIT_RISK_PATH || location.pathname === REPORTS_OPERATIONAL_RISK_PATH;

    const isSelectedSubMenu = (path) => location.pathname === path

    return (
        <>
            <Button
                endIcon={<ArrowDropDownOutlined />}
                sx={{ ...MENU_STYLE, color: isSelectedMainMenu() ? BLUE : BLACK }}
                onClick={handleOnClickMenu}
            >
                Informes
            </Button>
            <Menu
                anchorEl={dropdown}
                open={isOpen()}
                onClose={handleOnCloseSubmenu}
            >
                <MenuItem
                    onClick={handleOnClickSubmenu(REPORTS_CREDIT_RISK_PATH)}
                    sx={{color: isSelectedSubMenu(REPORTS_CREDIT_RISK_PATH) ? BLUE : BLACK }}

                >
                    Riesgo de Crédito y Contraparte
                </MenuItem>
                <MenuItem 
                    onClick={handleOnClickSubmenu(REPORTS_MARKET_RISK_PATH)}
                    sx={{color: isSelectedSubMenu(REPORTS_MARKET_RISK_PATH) ? BLUE : BLACK }}
                >
                    Riesgo de Mercado
                </MenuItem>
                <MenuItem
                    onClick={handleOnClickSubmenu(REPORTS_OPERATIONAL_RISK_PATH)}
                    sx={{color: isSelectedSubMenu(REPORTS_OPERATIONAL_RISK_PATH) ? BLUE : BLACK }}
                >
                    Riesgo Operacional
                </MenuItem>
            </Menu>
        </>
    );
}


const MenuIcons = () => {
    const { account } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleOnClickConfiguration = (event) => {
        navigate(CONFIGURATION_PATH);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
            justifyContent="flex-end"
            sx={{ mr: '50px', mt: '4px' }}
        >
            <IconButton onClick={handleOnClickConfiguration}>
                <Settings />
            </IconButton>
            <Box>
                <IconButton onClick={handleClick}>
                    <AccountCircle />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}

                >
                    <MenuItem onClick={handleClose}>
                        <IconButton>
                            <AccountCircle />
                        </IconButton>
                        <Typography variant="body1" style={{ marginLeft: '10px' }}>
                            {account?.name}
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </Stack>
    );
}



const TopMainMenu = () => {
    return (
        <Grid
            container
            padding={2}
        >
            <Grid
                item
                sm={7}
                md={7}
                lg={3}
                xl={4}
            >
                <Logo />
            </Grid>

            {/* Menus */}
            <Grid
                item
                sm={5}
                md={5}
                lg={7}
                xl={4}
                sx={{mt: '4px' }}
            >
                <Stack
                    justifyContent="center"
                    direction="row"
                >
                    <ExecutionMenu />
                    <CDMExecutions />
                    <ReportsMenu />
                </Stack>
            </Grid>

            {/* Icons */}
            <Grid
                item
                sm={2}
                md={2}
                lg={2}
                xl={4}
            >
                <MenuIcons/>
            </Grid>
        </Grid >
    );
}

  

const DrawerMainMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [openSubmenu, setOpenSubmenu] = useState({});

    const handleSubmenuClick = (submenu) => (event) => {
        event.stopPropagation(); 
        setOpenSubmenu(prevOpen => ({
        ...prevOpen,
        [submenu]: !prevOpen[submenu]
        }));
    };

    const handleFinalItemClick = (path) => {
        setIsDrawerOpen(false);
        navigate(path);
    };

    const list = () => (
        <Box
            sx={{
                width: 250,
                backgroundColor: 'white',
            }}
            onClick={() => setIsDrawerOpen(false)}
        >
            <List>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                    justifyContent="center"
                    sx={{ mt: '4px', mb: '5px' }}
                >
                    <IconButton>
                        <Settings />
                    </IconButton>
                    <IconButton>
                        <AccountCircle />
                    </IconButton>
                </Stack>
                <Divider orientation="horizontal" />
                <ListItemButton onClick={() => handleFinalItemClick(PROCESSES_PATH)}>
                    <ListItemText 
                        primary={"Procesos"} 
                        sx={(location.pathname === PROCESSES_PATH) ? { color: BLUE } : null}
                    />
                </ListItemButton>
                <ListItemButton onClick={() => handleFinalItemClick(CDM_EXECUTIONS_PATH)}>
                    <ListItemText 
                        primary={"CdM Ejecuciones"} 
                        sx={location.pathname === CDM_EXECUTIONS_PATH ? { color: BLUE } : null}
                    />
                </ListItemButton>
                <ListItemButton onClick={handleSubmenuClick("Informes")}>
                    <ListItemText 
                        primary={"Informes"} 
                        sx={location.pathname === REPORTS_CREDIT_RISK_PATH
                            || location.pathname === REPORTS_MARKET_RISK_PATH
                            || location.pathname === REPORTS_OPERATIONAL_RISK_PATH ? { color: BLUE } : null}
                    />
                    {openSubmenu["Informes"] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                    <Collapse in={openSubmenu["Informes"]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ paddingLeft: '2em' }} onClick={() => handleFinalItemClick(REPORTS_CREDIT_RISK_PATH)}>
                                <ListItemText 
                                    primary={"Riesgo de Crédito y Contraparte"} 
                                    sx={location.pathname === REPORTS_CREDIT_RISK_PATH ? { color: BLUE } : null}
                                />
                            </ListItemButton>
                            <ListItemButton sx={{ paddingLeft: '2em' }} onClick={() => handleFinalItemClick(REPORTS_MARKET_RISK_PATH)}>
                                <ListItemText 
                                    primary={"Riesgo de Mercado"} 
                                    sx={location.pathname === REPORTS_MARKET_RISK_PATH ? { color: BLUE } : null}
                                />
                            </ListItemButton>
                            <ListItemButton sx={{ paddingLeft: '2em' }} onClick={() => handleFinalItemClick(REPORTS_OPERATIONAL_RISK_PATH)}>
                                <ListItemText 
                                    primary={"Riesgo Operacional"} 
                                    sx={location.pathname === REPORTS_OPERATIONAL_RISK_PATH ? { color: BLUE } : null}
                                />
                            </ListItemButton>
                        </List>
                    </Collapse>

            </List>
        </Box>
    );
    
    return (
        <Grid
            container
            padding={2}
        >
            <Grid
                item
                lg={4}
                xl={4}
            >
                <Stack spacing={1} direction="row">
                    <IconButton sx={{color: BLACK}} onClick={() => setIsDrawerOpen(true)}>
                        <MenuIcon sx={{ fontSize: '28px' }} />
                    </IconButton>
                    <Logo />
                </Stack>
            </Grid>
            <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                {list()}
            </Drawer>
        </Grid >
    );
}

export default function Header() {
    const { isBelowLgScreen } = useResponsive();

    return (
        <>
            {isBelowLgScreen ?
                <DrawerMainMenu />
                :
                <TopMainMenu />
            }
        </>
    );
}
import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useErrors } from "../hooks/useErrors";

export function executionFilesService() {
    const { addQueryParameters, handleDownload } = useApi();
    const { startLoading, stopLoading, setDialogErrorState, clearDialogError } = useErrors();

    const getExecutionFile = async ({ process_id, month, year, result_file, fileDownloadName }) => {
        const key = 'getExecutionFile';
        var fileUrl = new URL(window.REACT_APP_API_URL + '/execution/' + process_id);
        var queryParams = {month: month, year: year, result_file: result_file};
        fileUrl = addQueryParameters(fileUrl, queryParams);
        startLoading(key);
        clearDialogError(key);
        try {
            const response = await handleDownload(fileUrl, fileDownloadName);
            if (!response.success) {
                setDialogErrorState(key, "Error encontrando la parametrización.");
                Sentry.captureException(response, {
                    tags: { service: 'executionFilesService', key },
                    contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
                });
                return null;
            }
        } catch (error) {
            setDialogErrorState(key, "Error encontrando la parametrización.");
            Sentry.captureException(error, {
                tags: { service: 'executionFilesService', key },
                contexts: { apiCall: { url: fileUrl.toString(), method: 'GET' } },
            });
            throw error;
        } finally {
            stopLoading(key);
        }
    }

    return { getExecutionFile }
}